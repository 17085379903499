import PaginationModel from "../Pagination/PaginationModel";
import StoreModel from "./storeModel";

class StoreListPaginationModel {
    private _store!: StoreModel[];
    private _pagination!: PaginationModel;

    public static parseJson(store: StoreModel[],paginationItem: PaginationModel) {
        const storeListWithPagination: StoreListPaginationModel = new StoreListPaginationModel();

        storeListWithPagination.store = store;
        storeListWithPagination.pagination = paginationItem;

        return storeListWithPagination;
    }

    public get store() {
        return this._store;
    }
    public set store(store: StoreModel[]) {
        this._store = store;
    }
    public get pagination() {
        return this._pagination;
    }
    public set pagination(pagination: PaginationModel) {
        this._pagination = pagination;
    }
}

export default StoreListPaginationModel;