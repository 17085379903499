import { API } from "../../../config";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { serverAPIPath } from "../config/apiPath";


class PasswordService {

    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async verify() {
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.VERIFY_PASSWORD}`, this.payload, this.headers).post();
        let isPasswordVerified: string;
        if(response?.data?.success==true) {
            isPasswordVerified = response?.data?.message;
            return isPasswordVerified;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }

    async update() {
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.UPDATE_PASSWORD}`, this.payload, this.headers).put();
        let isPasswordUpdated: string;
        if(response?.data?.success==true) {
            isPasswordUpdated = response?.data?.message;
            return isPasswordUpdated;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }

    async reset(){
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.RESET_PASSWORD}`, this.payload).put();
        let isPasswordReseted: string;
        if(response?.data?.success) {
            isPasswordReseted = response?.data?.message;
            return isPasswordReseted;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }
}

export default PasswordService;