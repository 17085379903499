import PaginationModel from "../Pagination/PaginationModel";
import UserModel from "./userModel";

class UserPagination {
    private _users!: UserModel[];
    private _pagination!: PaginationModel;

    public static parseJson(users: UserModel[], paginationItem: PaginationModel) {
        const userWithPagination: UserPagination = new UserPagination();

        userWithPagination.users = users;
        userWithPagination.pagination = paginationItem;

        return userWithPagination;
    }

    public get users() {
        return this._users;
    }
    public set users(users: UserModel[]) {
        this._users = users;
    }
    public get pagination() {
        return this._pagination;
    }
    public set pagination(pagination: PaginationModel) {
        this._pagination = pagination;
    }
}

export default UserPagination;
