import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/user/userSlice";
import snackbarReducer from "./features/snackbar/snackbarSlice";
import commonReducer from "./features/common/commonSlice";
import loaderReducer from "./features/loader/loaderSlice";
import permissionReducer from "./features/roleBasedPermission/permissionSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
    key: "root",
    storage,
    blacklist: ["loaderss"],
};

const rootReducer = combineReducers({
    user: userReducer,
    snackbar: snackbarReducer,
    loaderss: loaderReducer,
    common: commonReducer,
    permission: permissionReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type IRootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);