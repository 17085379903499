export const mockAPIPath = {
    LOGIN : "./data/login.json",
    REGISTER : "./data/register.json",
    VERIFY_PASSWORD: "./data/register.json",
    UPDATE_PASSWORD: "./data/register.json",
    RESET_PASSWORD: "./data/register.json",
    GENERATE_OTP: "./data/register.json",
    VERIFY_OTP: "./data/register.json",
    VERIFY_EMAIL: "./data/register.json",
    ROLES_BASED_PERMISSION: "./data/exhibitionUserPermission.json",
};

export const serverAPIPath = {
    LOGIN : "/admin/login",
    LOGOUT: "/admin/logout",
    REGISTER : "/admin/register",
    VERIFY_PASSWORD: "/admin/verify-old-password",
    UPDATE_PASSWORD: "/admin/update-password",
    RESET_PASSWORD: "/admin/reset-password",
    GENERATE_OTP: "/otp/generate",
    VERIFY_OTP: "/otp/verify",
    VERIFY_EMAIL: "/admin/verify-email",
    ROLES_BASED_PERMISSION: "/rms/roles"
};