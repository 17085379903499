import { API } from "../../../../config";
import AxiosUtils from "../../../../utils/AxiosUtils";
import Cookies from "../../../../utils/Cookies";
import { serverAPIPath, mockAPIPath } from "../config/apiPath";


class PaginationLimitServices {

    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async PaginationLimit() {

        // let fileupload;
        const response = this.isHitDummyURL ? 
            await new AxiosUtils(mockAPIPath.PAGINATION_LIMIT, this.payload, this.headers).get() : 
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.PAGINATION_LIMIT}`, this.payload, this.headers).post();
        if(response?.data?.success) {
            const paginationLimit = response?.data?.message;
            return paginationLimit;
        }
        else {
            throw new Error(response);
        }

    }

}
export default PaginationLimitServices;

