import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./i18n";
import { Provider } from "react-redux"; 
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";
import "./theme/scss/style.scss";
import { datadogLogs } from "@datadog/browser-logs";

if(process.env.REACT_APP_DATADOG_ENV !== "local"){
    datadogLogs.init({
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || "",
        site: "ap1.datadoghq.com",
        service: process.env.REACT_APP_DATADOG_SERVICE,
        env: process.env.REACT_APP_DATADOG_ENV,
        forwardErrorsToLogs: true,
        sessionSampleRate: 100
    });
}


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
    // <React.StrictMode>
    <Suspense fallback="loading...">
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </Suspense>
    //  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
