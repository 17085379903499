import React, { useEffect, useRef, useState } from "react";
import {
    Container,
    Grid,
    TextField,
    Button,
    Typography,
    IconButton,
    InputAdornment,
} from "@mui/material";

import { imagePath } from "../../../utils/assetUtils";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CONSTANTS from "../../../constants/constants";
import AuthService from "../service/AuthService";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SimpleReactValidator from "simple-react-validator";
import { ROUTES } from "../../../constants/routes";
import UnderMaintenanceService from "../../EditMaintenance/services/UnderMaintenanceService";
import { useAppDispatch } from "../../../redux/hooks";
import { commonActions } from "../../../redux/features/common/commonSlice";

const Login = (props: any) => {

    window.onpopstate = function () {
        if (window.location.href.includes(ROUTES.REGISTER)) {
            window.history.forward();
        }
    };
    const dispatch = useAppDispatch();


    const { login, hideLoader, showSnackbar, setIsMsgRequired, isMsgRequired, setMsgVisibility } = props;

    {
    /* Language translate function*/
    }
    const [t] = useTranslation();
    
    {
    /* Language translate function*/
    }

    const navigate = useNavigate();

    const [passwordType, setPasswordType] = useState("password");
    // const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    //     null
    // );
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    // const [emailError, setEmailError] = useState("");
    const passwordError = "";
    // const [openSnackBar, setOpenSnackBar] = useState(false);
    // const [alertMessage, setAlertMessage] = useState("");
    // const [successAlert, setSuccessAlert] = useState(false);

    const handleOnBlur = (e: any) => {
        const { name } = e.target;
        validator.current.showMessageFor(name);
    };

    // validation
    const [, forceUpdate] = useState(0);

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                email: t("login.please_enter_valid_email"),
                regex: t("login.please_enter_valid_password"),
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );

    useEffect(() => {
        forceUpdate(1);
    });
    useEffect(()=>{
        GetMaintenancePage();
    },[]);

    useEffect(()=>{
        setIsMsgRequired(localStorage.getItem("systemUpdateNotification"));
        setMsgVisibility(localStorage.getItem("systemUpdateNotificationVisibility"));
    },[localStorage.getItem("systemUpdateNotification"), localStorage.getItem("systemUpdateNotificationVisibility")]);

    const GetMaintenancePage = () => {
        const isHitDummyURL = false;
        new UnderMaintenanceService({}, isHitDummyURL)
            .UnderMaintenanceGetService()
            .then((res: any)=>{
                localStorage.setItem("systemUpdateNotificationVisibility",res?.systemUpdateNotificationVisibility ? String(res?.systemUpdateNotificationVisibility) : "0");
                localStorage.setItem("systemUpdateNotification", res.systemUpdateNotification ? res.systemUpdateNotification : "");
                dispatch(commonActions.IS_MAINTENANACE(res.maintenanceMode));
                setIsMsgRequired(res.systemUpdateNotification);
                setMsgVisibility(res?.systemUpdateNotificationVisibility);
            })
            .catch((err)=>{
                showSnackbar(err?.message, false);
            });
    };
    //validation

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        name === "email" ? setEmail(value) : setPassword(value);
        validator.current.hideMessageFor(name);
    };

    const handleSubmit = () => {
        if (validator.current.allValid()) {
            hideLoader(true);
            const payload = { email, password, name };
            const isHitDummyURL = false;
            new AuthService(payload, isHitDummyURL)
                .login()
                .then((res) => {
                    hideLoader(false);
                    const { accessToken, email, name, userType ,perPageLimit,roleId} = res;
                    login(accessToken, email, name, userType ,perPageLimit,roleId);
                    // setSuccessAlert(true);
                    navigate(ROUTES.INITIAL_PAGE);
                })
                .catch((err) => {
                    hideLoader(false);
                    showSnackbar(err?.message, false); 
                });
        } else {
            validator.current.showMessages();
        }
    };

    // const handleCloseSnackBar = () => {
    //     setOpenSnackBar(false);
    // };

    const handleKeyPress = (e: any) => {
        if (e.keyCode == 13) {
            handleSubmit();
        }
    };

    // const handleClickShowPassword = () => {
    //     setPasswordType("password");
    // };

    const handleMouseDownPassword = () => {
        passwordType=="password" ? setPasswordType("text") : setPasswordType("password");
    };

    return (
        <>
            <div className="main">
                {/* Login page starts here */}                
                <Container sx={ isMsgRequired ? { mt: { xl: 8, lg: 8, md: 8, sm: 8, xs: 14 } } : {}}>
                    <Grid container>
                        {/* offset grid with column*/}
                        <Grid item xs={12} xl={4} lg={4} md={2} sm={12}></Grid>
                        {/* main grid column! */}                          
                        <Grid item xs={12} xl={4} lg={4} md={8} sm={12}>
                            <img
                                className="logo-img"
                                alt="cainz"
                                src={imagePath("logo-large.png")}
                            />
                            <Grid sx={{ mb: 2 }}>
                                <TextField
                                    fullWidth
                                    type={email}
                                    name="email"
                                    value={email}
                                    label={t("login.email_address")}
                                    variant="outlined"
                                    onChange={(e) => handleChange(e)}
                                    onKeyUp={(e) => handleKeyPress(e)}
                                    onBlur={(e) => handleOnBlur(e)}
                                />
                                {/* validation message */}

                                {validator.current.message("email", email, [
                                    "required",
                                    "email",
                                ])}
                            </Grid>
                                       
                            <Grid sx={{ mb: 2 }}>
                                <TextField
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    // onTouchStart={handleMouseDownPassword}
                                                    // onTouchEnd={handleClickShowPassword}
                                                    onClick={handleMouseDownPassword}
                                                    // onMouseDown={handleMouseDownPassword}
                                                >
                                                    { passwordType=="password" ? 
                                                        <VisibilityIcon /> : 
                                                        <VisibilityOffIcon />
                                                    }
                                                            
                                                            
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    type={passwordType}
                                    fullWidth
                                    label={t("login.password")}
                                    variant="outlined"
                                    onChange={(e) => handleChange(e)}
                                    error={passwordError ? true : false}
                                    helperText={passwordError}
                                    onKeyUp={(e) => handleKeyPress(e)}
                                    name="password"
                                    value={password}
                                    onBlur={(e) => handleOnBlur(e)}
                                />
                                {validator.current.message("password", password, [
                                    "required",
                                    { regex: CONSTANTS.PASSWORD_REGEX },
                                ])}
                            </Grid>
                            

                            <Grid>
                                <Typography
                                    sx={{ my: 2 }}
                                    className="forgot-text"
                                    onClick={() => navigate(ROUTES.SEND_OTP)}
                                >
                                    {t("login.forgot_password")}
                                </Typography>
                            </Grid>
                            <Button fullWidth onClick={handleSubmit}>{t("login.submit")}</Button>
                            {/* offset grid with column*/}
                            <Grid item xs={12} xl={4} lg={4} md={2} sm={12}></Grid>
                        </Grid>
                    </Grid>
                </Container>
                
                {/* Login page ends here */}
            </div>
        </>
    );
};

export default Login;
