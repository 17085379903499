import React, { useEffect, useState } from "react";
import OrderDetailService from "./services/orderDetailService";
import PageTitle from "../common/PageTitle";
import { useTranslation } from "react-i18next";
import {  Box,  Button, Container, Grid,  Paper,  Table,  TableBody,  TableCell, TableContainer, TableHead, TableRow, TextareaAutosize, Typography, } from "@mui/material";
import Skeleton from "../common/Skeleton";
import Utility from "../../utils/Utility";
import { useParams } from "react-router-dom";



const OrderDetail = (props:any) => {
    const { showSnackbar } = props;
    const [confirmShipmentData, setConfirmShipmentData] = useState<any>();
    const [skeleton, setSkeleton] = useState<boolean>(true);
    const [productSummary, setProductSummary] = useState<any>([]);
    const params = useParams();
    const { t, i18n } = useTranslation();
    useEffect(()=>{
        confirmedData();
    },[]);

    const confirmedData = () => {
        const orderID = params.id;
        const code = params.code;
        const payload = {};
        const isHitDummyURL = false;
        new OrderDetailService(payload, isHitDummyURL)
            .OrderDetail(orderID,code)
            .then((res) => {
                setConfirmShipmentData(res);
                setProductSummary(res?.products);
                setSkeleton(false);
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
                setSkeleton(false);
            });
    };
    return (
        <Container className="oms-Shipment-table">
            <PageTitle title={t("oms.order_details")} />

            {skeleton ? (
                <Skeleton varient="rectangular" width={0} height={253} count="10" />
            ) : (
                <>
                    <Grid container className="shipment-detail" sx={{ mb: 4 }}>
                        <Grid item lg="auto" sx={{ mx: 1 }}>
                            <Typography component="h2" className="text_wrap_data">
                                {t("oms.order_id")} &#x3A; {confirmShipmentData?.orderId}
                            </Typography>
                            <Typography component="h2" className="text_wrap_data">
                                {t("oms.purchase_date")} &#x3A; {confirmShipmentData?.orderDate}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{mb:2}}>
                        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                            <Box className="ship_card">
                                <Grid container spacing={2}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Typography component="h4">{t("oms.ship_to")}</Typography>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Grid>
                                            <Typography className="text_wrap_data">
                                                {confirmShipmentData?.shippingPostalCode}
                                            </Typography>
                                            <Typography className="text_wrap_data">
                                                {confirmShipmentData?.shippingPrefecture}
                                            </Typography>
                                            <Typography className="text_wrap_data">
                                                {confirmShipmentData?.shippingAddress1}
                                            </Typography>
                                            <Typography className="text_wrap_data">
                                                {confirmShipmentData?.shippingAddress2}
                                            </Typography>
                                            <Typography className="text_wrap_data">
                                                {confirmShipmentData?.shippingAddress3}
                                            </Typography>
                                            <Typography className="text_wrap_data">
                                                {confirmShipmentData?.shippingSurname}{confirmShipmentData?.shippingFirstName}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Grid container>
                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                                <Typography>{t("oms.contact_buyer")} : </Typography>
                                            </Grid>
                                            
                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                                <Typography className="text_wrap_data">{`${confirmShipmentData?.ordererName} ${confirmShipmentData?.nameOfOrderer} `}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                                <Typography>{t("oms.phone")} : </Typography>
                                            </Grid>
                                            
                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                                <Typography className="text_wrap_data">{confirmShipmentData?.ordererPhoneNumber}</Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container>
                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                                <Typography>{t("oms.email")} : </Typography>
                                            </Grid>
                                            
                                            <Grid item lg={6} md={6} sm={6} xs={6}>
                                                <Typography className="text_wrap_data">{confirmShipmentData?.ordererEmail}</Typography>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                 
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                            <Box className="confirm_card">
                                <Grid container spacing={2}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Typography component="h4" className="text_wrap_data">
                                            {t("oms.order_sales")}
                                        </Typography>
                                    </Grid>

                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Grid container>
                                            <Grid item lg={7} md={8} sm={8} xs={8}>
                                                <Typography >{t("oms.products_subtotal")} :</Typography>
                                            </Grid>
                                            <Grid item lg={5} md={4} sm={4} xs={4} >
                                                <Typography className="flex-end">
                                                    &yen; {new Utility().numberWithCommas(confirmShipmentData?.totalProductPrice)}
                                                </Typography>
                                            </Grid>
                                            {/* <Grid item lg={2} md={4} sm={1} xs={12}></Grid> */}
                                        </Grid>
                                        <Grid container >
                                            <Grid item lg={8} md={8} sm={8} xs={8}>
                                                <Typography className="text_wrap_data">{t("oms.individual_shipping")} :</Typography>
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={4} xs={4} >
                                                <Typography className="flex-end">
                                                    &yen; {new Utility().numberWithCommas(confirmShipmentData?.totalShippingPrice)}
                                                </Typography>
                                            </Grid>
                                            {/* <Grid item lg={6} md={4} sm={1} xs={12}></Grid> */}

                                                
                                        </Grid>
                                        {confirmShipmentData?.totalRefundAmount!==undefined?
                                            <>
                                                <Grid container >
                                                    <Grid item lg={7} md={8} sm={8} xs={8}>
                                                        <Typography className="text_wrap_data">{t("oms.refund_total")} :</Typography>
                                                    </Grid>
                                                    <Grid item lg={5} md={4} sm={4} xs={4} >

                                                        <Typography className="flex-end refund_order_text_color">
                                                            -&yen; {new Utility().numberWithCommas((-confirmShipmentData?.totalRefundAmount).toString())}
                                                        </Typography>
                                                    </Grid>
                                                    {/* <Grid item lg={6} md={4} sm={1} xs={12}></Grid> */}

                                                </Grid>
                                                
                                                <Grid container >
                                                    <Grid item lg={7} md={8} sm={8} xs={8}>
                                                        <Typography className="text_wrap_data fw">{t("oms.total")} :</Typography>
                                                    </Grid>
                                                    <Grid item lg={5} md={4} sm={4} xs={4} >

                                                        <Typography className="flex-end fw">
                                                            &yen; {new Utility().numberWithCommas(confirmShipmentData?.totalOrderAmount+confirmShipmentData?.totalRefundAmount )}
                                                        </Typography>
                                                    </Grid>
                                                    {/* <Grid item lg={6} md={4} sm={1} xs={12}></Grid> */}

                                                </Grid>
                                            </>
                                            :
                                            <Grid container >
                                                <Grid item lg={7} md={8} sm={8} xs={8}>
                                                    <Typography className="text_wrap_data fw">{t("oms.total")} :</Typography>
                                                </Grid>
                                                <Grid item lg={5} md={4} sm={4} xs={4} >
                                                    <Typography className="flex-end fw">
                                                        &yen; {new Utility().numberWithCommas(confirmShipmentData?.totalOrderAmount)}
                                                    </Typography>
                                                </Grid>
                                                {/* <Grid item lg={6} md={4} sm={1} xs={12}></Grid> */}

                                            </Grid>
                                        }
                                        <Grid container>
                                            <Grid item lg={7} md={8} sm={8} xs={8}>

                                                <Typography className="text_wrap_data">{t("oms.total_point_awarded")} :</Typography>
                                            </Grid>
                                            <Grid item lg={5} md={4} sm={4} xs={4} >
                                                <Typography className="flex-end">
                                                    {new Utility().numberWithCommas(confirmShipmentData?.totalPointAwarded)}{t("oms.points")}
                                                </Typography>
                                            </Grid>
                                            {/* <Grid item lg={6} md={4} sm={1} xs={12}></Grid> */}

                                        </Grid>
                                        {confirmShipmentData?.totalRefundPoints!== undefined?
                                            <Grid container>
                                                <Grid item lg={7} md={8} sm={8} xs={8}>
                                                    <Typography className="text_wrap_data">{t("oms.total_returned_points")} :</Typography>
                                                </Grid>
                                                <Grid item lg={5} md={4} sm={4} xs={4} >
                                                    <Typography className="flex-end">
                                                        {new Utility().numberWithCommas(confirmShipmentData?.totalRefundPoints == 0 ? "0" : ((-confirmShipmentData?.totalRefundPoints).toString()))}{t("oms.points")}
                                                    </Typography>
                                                </Grid>
                                                {/* <Grid item lg={6} md={4} sm={1} xs={12}></Grid> */}
                                            </Grid>
                                            :null}                                                                
                                    </Grid>                                                                                     
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                            <Box className="shipcard">
                                <Typography component="h4"sx={{mb:2}} >{t("oms.seller_memo")}</Typography>
                                <TextareaAutosize
                                    className="textarea"
                                    readOnly
                                    maxRows={12}
                                    minRows={12}
                                    name="reasonMessage"
                                    // style={{ width: "100%" }}
                                    value={confirmShipmentData?.sellerMemo}                            
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container className="shipment_product_table">
                        <Grid container spacing={2}>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table" >
                                        <TableHead>
                                            <TableRow className="table_head_bg_color">
                                                <TableCell>
                                                    <Box>
                                                        {t("oms.status")}
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    <Box>
                                                        {t("oms.image")}
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    <Box>
                                                        {t("oms.product_information")}
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    <Box>
                                                        {t("oms.more_information")}
                                                    </Box>
                                                </TableCell>
                                                <TableCell className="text_right">
                                                    <Box>
                                                        {t("oms.quantity")}
                                                    </Box>
                                                </TableCell>
                                                <TableCell className="text_right">
                                                    <Box>
                                                        {t("oms.unit_price")}
                                                    </Box>
                                                </TableCell>
                                                <TableCell className="text_right">
                                                    <Box>
                                                        {t("oms.shipping_fee")}
                                                    </Box>
                                                </TableCell>
                                                <TableCell className="text_right">
                                                    <Box>
                                                        {t("oms.process")}
                                                    </Box>
                                                </TableCell>
                                                <TableCell className="text_right">
                                                    <Box>
                                                        {t("oms.sales")}
                                                    </Box>
                                               
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className="shipment-product-detail">
                                            {productSummary?.map(
                                                (item: any, index: number) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                <Button
                                                                    variant="outlined"
                                                                    sx={{ wordBreak: "keep-all" }}
                                                                >
                                                                    {item?.status == 6
                                                                        ? t("oms.shipped")
                                                                        : item?.status == 9
                                                                            ? t("oms.cancel")
                                                                            : item?.status == 5
                                                                                ? t("oms.preparing_for_shipment")
                                                                                : t("oms.unshipped")}
                                                                </Button>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Grid sx={{ mr: 2 }}>
                                                                    <Box
                                                                        component="img"
                                                                        sx={{ width: 100 }}
                                                                        src={item?.image}
                                                                        alt="product image"
                                                                    />
                                                                </Grid>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Box className="shipment_product_table_box">
                                                                    <Grid>
                                                                        <Typography >{t("oms.product_name")} : {item?.productName}</Typography>
                                                                        <Typography >
                                                                            {t("oms.product_code")} : {item?.productCode}
                                                                        </Typography>
                                                                        <Typography>
                                                                            {t("oms.cp_id")} : {item?.cainzProductCode}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell>
                                                        
                                                                <Typography>
                                                                    {item?.productDeliveryDate}
                                                                </Typography>
                                                            </TableCell>

                                                            <TableCell className="text_right">
                                                                <Typography>  {item?.salesVolume}</Typography>
                                                            </TableCell>

                                                            <TableCell className="text_right">
                                                             &yen; {new Utility().numberWithCommas(item?.productPriceIncludingTax)}
                                                            </TableCell>
                                                            <TableCell className="text_right">
                                                             &yen; {new Utility().numberWithCommas(item?.individualShippingFeeIncludingTax )}
                                                            </TableCell>
                                                            <TableCell className="text_right">
                                                                <Typography >
                                                            &yen;{" "}
                                                                    { new Utility().numberWithCommas(item?.productPriceIncludingTaxSubtotal)}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell className="text_right">
                                                             &yen; {new Utility().numberWithCommas((item?.productPriceIncludingTaxSubtotal) + (item?.individualShippingFeeIncludingTax))}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                }
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Grid>

                </>

            )}
        </Container>

    );
};

export default OrderDetail;