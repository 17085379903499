class UserModel {
    private _userId!: number;
    private _name!: string;
    private _email!: string;
    private _userType!: number;
    private _accessToken!: string;
    private _status!: number;
    private _password!: string;
    private _invitationId!: number;
    private _statusText!: any;
    private _perPageLimit!: number;
    private _roleName!: string;
    private _roleId!: number;


    public static parseJson(itemUser: UserModel) {
        const users: UserModel = new UserModel();
        users._userId = itemUser.userId;
        users.name = itemUser.name;
        users.email = itemUser.email;
        users.userType = itemUser.userType;
        users.accessToken = itemUser.accessToken;
        users.status = itemUser.status; 
        users.password = itemUser.password;
        users.statusText = itemUser.statusText;
        users.invitationId = itemUser.invitationId;
        users.perPageLimit = itemUser.perPageLimit;
        users.roleName = itemUser.roleName;
        users.roleId = itemUser.roleId;
        return users;
    }

    public get userId() {
        return this._userId;
    }
    public set userId(userId: number) {
        this._userId = userId;
    }
    public get name() {
        return this._name;
    }
    public set name(name: string) {
        this._name = name;
    }
    public get email() {
        return this._email;
    }
    public set email(email: string) {
        this._email = email;
    }
    public get userType() {
        return this._userType;
    }
    public set userType(userType: number) {
        this._userType = userType;
    }
    public get accessToken() {
        return this._accessToken;
    }
    public set accessToken(accessToken: string) {
        this._accessToken = accessToken;
    }
    public get status() {
        return this._status;
    }
    public set status(status: number) {
        this._status = status;
    }
    public get password() {
        return this._password;
    }
    public set password(password: string) {
        this._password = password;
    }
    public get statusText() {
        return this._statusText;
    }
    public set statusText(statusText: any) {
        this._statusText = statusText;
    }
    public get invitationId() {
        return this._invitationId;
    }
    public set invitationId(invitationId: number) {
        this._invitationId = invitationId;
    }
    public get perPageLimit() {
        return this._perPageLimit;
    }
    public set perPageLimit(perPageLimit: number) {
        this._perPageLimit = perPageLimit;
    }
    public get roleName() {
        return this._roleName;
    }
    public set roleName(roleName: string) {
        this._roleName = roleName;
    }
    public get roleId() {
        return this._roleId;
    }
    public set roleId(roleId: number) {
        this._roleId = roleId;
    }
}

export default UserModel;