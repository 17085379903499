import React, { useState, useRef, useEffect } from "react";
import { Container, Grid, TextField,  Button,  Typography } from "@mui/material";
import { imagePath } from "../../../utils/assetUtils";
import { useTranslation } from "react-i18next";
import OTPService from "../service/OTPService";
import { useNavigate, useLocation } from "react-router";
import SimpleReactValidator from "simple-react-validator";
import { ROUTES } from "../../../constants/routes";
import CONSTANTS from "../../../constants/constants";
import { Link } from "react-router-dom";

const VerifiedOTP = (props: any) => {
    const { showSnackbar, handleLoader } = props;

    window.onpopstate = function (e: any) {
        if (window.location.href.includes(ROUTES.SEND_OTP)) {
            const responseMessage = t("verify_otp.are_you_sure_want_to_exit");
            const response = confirm(responseMessage);
            if (!response) {
                e.stopPropagation();
                window.history.forward();
            } else {
                navigate(ROUTES.INITIAL_PAGE);
            }
        }
    };

    /* Language translate function*/
    const [t] = useTranslation();
    /* Language translate function*/

    const navigate = useNavigate();

    const [otp, setOtp] = useState<any>();
    // const [error, setError] = useState("");
    // const [openSnackBar, setOpenSnackBar] = useState(false);
    // const [alertMessage, setAlertMessage] = useState("");
    const location = useLocation();
    const email = location?.state?.email;
    // const [successAlert, setSuccessAlert] = useState(false);
    // const [fullWidthL, setFullWidthL] = useState<number>(0);
    const [otpFailError,setOtpFailError] =useState<any>("");



    // validation
    const [, forceUpdate] = useState(0);

    // eslint-disable-next-line no-control-regex
    const isSingleByte = (value: string) => { return /^[\x00-\xFF]*$/.test(value); };

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                required: t("required.this_field_cannot_be_empty"),
            },
            validators: {
                SingleByteNumber: {
                    message: t("registerpage.single_byte_numbers"),
                    rule: (value) => isSingleByte(value),
                },
            }
        })
    );

    useEffect(() => {
        forceUpdate(1);
    });
    //validation

    const handleOnBlur = (e: any) => {
        const { name } = e.target;

        validator.current.showMessageFor(name);
    };

    const handleChange = (e: any) => {
        setOtpFailError("");
        const { name, value } = e.target;
        name === "otp" ? setOtp(value) : null;
        validator.current.hideMessageFor(name);
    };
    useEffect(() => {
        !email ? navigate(ROUTES.INITIAL_PAGE) : null;
    });

    const handleSubmit = () => {
        if (validator.current.allValid()) {
            handleLoader(true);
            const payload = { email, otp: +otp };
            const isHitDummyURL = false;
            new OTPService(payload, isHitDummyURL)
                .verify()
                .then((res) => {
                    handleLoader(false);
                    navigate(ROUTES.CREATE_PASSWORD, { state: { email: email } });
                    // setSuccessAlert(true);
                    showSnackbar(res, true);
                })
                .catch((err) => {
                    handleLoader(false);
                    err?.message == CONSTANTS.OTP_ERROR_MSG ?    
                        setOtpFailError(err?.message) :
                        showSnackbar(err?.message, false);
                });
        } else {
            validator.current.showMessages();
        }
    };

    const handleInputType = (e: any) => {
        e.target.value = e.target.value.replace(/[^0-9||０-９]/g, "");
        // if(e.target.value.match(/[\uff00-\uffff]/g)){
        //     setFullWidthL(1);
        //     if(fullWidthL==0){
        //         e.target.value = e.target.value.replace(/[\uFF10-\uFF19]/g, function(m:any) {
        //             return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
        //         });
        //     }else{
        //         setFullWidthL(0);
        //         e.target.value = e.target.value.replace(/[\uFF10-\uFF19]/g, function(m:any) {
        //             return "";
        //         });          
        //     }  
        // }
    };

    // const handleCloseSnackBar = () => {
    //     setOpenSnackBar(false);
    // };

    return (
        <>
            {/* VerifiedOTP page starts here */}
            <div className="main">
                <Container>
                    <Grid container>
                        {/* offset grid with column*/}
                        <Grid item xs={12} xl={4} lg={4} md={2} sm={12}></Grid>
                        {/* main grid column! */}
                        <Grid item xs={12} xl={4} lg={4} md={8} sm={12}>
                            <img
                                className="logo-img"
                                alt="cainz"
                                src={imagePath("logo-large.png")}
                            />
                            <Grid sx={{ mb: 2 }}>
                                <TextField
                                    fullWidth
                                    label={t("verify_otp.enterotp")}
                                    variant="outlined"
                                    onChange={(e) => handleChange(e)}
                                    name="otp"
                                    type="otp"
                                    value={otp ?? ""}
                                    inputProps={{ maxLength: 4 }}
                                    onInput={(e) => handleInputType(e)}
                                    onBlur={(e) => handleOnBlur(e)}
                                />
                                {validator.current.message("otp", otp, ["required","SingleByteNumber"])}
                                {otpFailError!==""?
                                    <Typography sx={{color:"red"}}>{otpFailError}</Typography>
                                    :""}
                                <Grid item xs={12} md={12} sx={{my:2}}>
                                    <Button fullWidth onClick={handleSubmit}>
                                        {t("verify_otp.verify")}
                                    </Button>
                                </Grid>

                                <Link to={ROUTES.INITIAL_PAGE}>
                                    <Typography className="Order_id_link float_right">{t("send_otp.back_to_login")}</Typography>                           
                                </Link>
                            </Grid>
                        </Grid> 
                        {/* offset grid with column*/}
                        <Grid item xs={12} xl={4} lg={4} md={2} sm={12}></Grid>
                    </Grid>
                </Container>
               
                {/* VerifiedOTP page starts here */}
            </div>
        </>
    );
};

export default VerifiedOTP;
