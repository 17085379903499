import React, { useEffect, useRef, useState } from "react";
import { Autocomplete, Box, Button, Container, FormControl, Grid, TextField, } from "@mui/material";
import PageTitle from "../common/PageTitle";
import { useTranslation } from "react-i18next";
import Pagination from "../common/Pagination";
import CONSTANTS, { LIST_LIMIT, SHEET_NAME } from "../../constants/constants";
import SimpleReactValidator from "simple-react-validator";
import Skeleton from "../common/Skeleton";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PaymentHistoryService from "./services/paymentService";
import MUIDataTable from "mui-datatables";
import Utility from "../../utils/Utility";
import downloadDataToExcel from "../../utils/ExcelDownloadUtility";
import SalesHistoryServices from "./services/paymentService";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import jaLocale from "date-fns/locale/ja";
import { useAppSelector } from "../../redux/hooks";
import DownloadButton from "../common/DownloadBtn";
import "dayjs/locale/ja";
import { StatementTemplate } from "../common/PDFTemplates/StatementTemplate";
import { BlobProvider, PDFViewer, pdf } from "@react-pdf/renderer";
import { paymentHistoryConfig } from "./config/paymentHistoryConfig";
import { Link } from "react-router-dom";

const PaymentHistory = (props: any) => {

    const { handleLoader, showSnackbar } = props;

    const firstPage = 1;
    const perPageLimit = useAppSelector((state: any) => state.common.perPageLimit);
    const userType = useAppSelector((state: any) => state.common.loggedInUserType);
    const utility = new Utility();

    const [currentPage, setCurrentPage] = useState(firstPage);
    const [limit, setLimit] = useState(!perPageLimit ? LIST_LIMIT.PAGINATION_LIST_LIMIT : perPageLimit);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [valueFrom, setValueFrom] = React.useState<Date | null>(null);
    const [valueTo, setValueTo] = React.useState<Date | null>(null);
    const [search, setSearch] = useState<string>("");
    const [sellerValue, setSellerValue] = useState("");
    const [sellerName, setSellerName] = useState<any>([]);
    const [fromParam, setFromParam] = useState<any>("");
    const [toParam, setToParam] = useState<any>("");
    const [skeleton, setSkeleton] = useState(true);
    const [historyData, setHistoryData] = useState<any>([]);
    const [resetData, setResetData] = useState(false);
    const [noOptions, setNoOptions] = useState<boolean>(false);
    const [dummyValue, setDummyValue] = useState<Date | null>(null);
    const [dateValue, setDateValue] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const apiData: any[][] = [];

    const { t, i18n } = useTranslation();
    const [, forceUpdate] = useState(0);

    const handleDateFrom = (value: any) => {
        const { $D, $M, $y } = value;
        let month = $M + 1;
        let dateto = $D;
        month = month < 10 ? "0" + month : month;
        dateto = dateto < 10 ? "0" + dateto : dateto;
        setFromParam(`${$y}/${month}/${dateto}`);


        setValueFrom(value);

    };
    const handleDateTo = (value: any) => {
        const { $D, $M, $y } = value;
        let month = $M + 1;
        let dateto = $D;
        month = month < 10 ? "0" + month : month;
        dateto = dateto < 10 ? "0" + dateto : dateto;
        setToParam(`${$y}/${month}/${dateto}`);
        setValueTo(value);
    };

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );

    useEffect(() => {
        forceUpdate(1);
    });

    useEffect(() => {
        if (search || resetData) {
            paymentHistoryData(firstPage, limit);
        }
        if (search == "") {
            setSellerName([]);
        }
    }, [search, resetData]);

    useEffect(() => {
        paymentHistoryData(firstPage, limit);
    }, []);

    const paymentHistoryData = (currentPage: number, limit: number) => {
        const payload = {};
        const isHitDummyURL = false;
        setSkeleton(true);
        setResetData(false);
        new PaymentHistoryService(payload, isHitDummyURL)
            .paymentHistoryList(currentPage, limit, toParam ?? "", fromParam ?? "", sellerValue)
            .then((res: any) => {
                setHistoryData(res);
                setLimit(limit);
                setCurrentPage(currentPage);
                setTotalCount(res?.pagination?.totalCount);
                setTotalPages(res?.pagination?.totalPages);
                setHistoryData(res?.paymentHistoryLogs);
                setSkeleton(false);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
            });

        document.querySelector(".from-date-seller-picker input")?.setAttribute("disabled", "true");
        document.querySelector(".to-date-seller-picker input")?.setAttribute("disabled", "true");
        document.querySelector(".date-picker-default input")?.setAttribute("disabled", "true");
    };

    const downloadPaymentHistoryData = () => {
        showSnackbar(t("activity_log.download_started"), true);
        setLoading(true);
        const payload = {};
        const isHitDummyURL = false;
        new PaymentHistoryService(payload, isHitDummyURL)
            .downloadPaymentHistory(toParam ?? "", fromParam ?? "", sellerValue)
            .then((res: any) => {
                handleOnExport(res?.paymentHistoryLogs);
                setSkeleton(false);
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
                setLoading(false);
                setSkeleton(false);
            });
    };

    const searchSellerNameInput = (search: string) => {
        const payload = {};
        const isHitDummyURL = false;
        new PaymentHistoryService(payload, isHitDummyURL)
            .SearchSellerName(search)
            .then((res: any) => {
                res.sellerNameLogs.length > 0 ? handleSearchDropdown(res?.sellerNameLogs) : setSellerName([]);
                setSkeleton(false);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
            });
    };

    const handleSearchChange = (e: any) => {
        e?.target?.value?.length > 0 ? setNoOptions(true) : setNoOptions(false);
        if (e?.target?.value == "") {
            setSellerName([]);
        }
        if (e?.target?.value?.trim() != "") {
            searchSellerNameInput(e?.target?.value);
        } else {

            setSellerName([]);
        }
    };

    const handleSearchValue = (e: any, value: any) => {
        if (value) {
            setSearch(value?.label);
            setSellerValue(value?.value);
        } else {
            setSearch("");
            setSellerValue("");

        }
    };

    const handleSearchDropdown = (dropdown: any) => {
        const uniquePartnerNames = new Set();
        let dropdownList: { label: any; value: any; }[] = [];
        if (dropdown.length > 0) {
            dropdown.map((item: any) => {
                if (!uniquePartnerNames.has(item.partnerName)) {
                    uniquePartnerNames.add(item.partnerName);
                    dropdownList.push({
                        label: `${item?.partnerName} (${item?.storeCode})`,
                        value: item.storeCode,
                    });
                }
            });
        }
        else {
            dropdownList = [];
        }
        setSellerName(dropdownList);
    };


    const handleSellerClick = (e: any) => {
        setSellerName([]);
    };



    const handleOnExport = (downloadArray?: any) => {
        const downloadData: any = [];
        downloadArray?.map((item: any) => {
            downloadData.push({
                "決済期間": item?.settlementPeriod,
                "出品者名": item?.sellerName,
                "受注金額": item?.orderAmount,
                "返金額": item?.refundAmount,
                "ポイント付与額": item?.grantedPointAmount,
                "Cainz手数料額": item?.cainzFee,
                "入金額": item?.transferAmount,
            });
        });
        const fileName = "支払履歴.xlsx";
        downloadDataToExcel(downloadData, fileName, SHEET_NAME.SALES_HISTORY);
        setLoading(false);
    };


    const handleSubmit = () => {
        if (validator.current.allValid()) {
            paymentHistoryData(firstPage, limit);
        }
        else {
            validator.current.showMessages();
        }
    };

    const downloadPDF = (sellerCode: string, settlementPeriod: string) => {
        handleLoader(true);
        const payload = {};
        const isHitDummyUrl = false;
        new PaymentHistoryService(payload, isHitDummyUrl)
            .salesSatementService(sellerCode, settlementPeriod)
            .then(async (res) => {
                const PDFData = res;
                const settlementPeriod = utility.getCustomDateYMDJP(PDFData?.settlementPeriod);
                const updatedSettlementPeriod = settlementPeriod.replaceAll(" ", "");
                const fileName = paymentHistoryConfig.PDF_DEFAULT_TEXT.concat("_", updatedSettlementPeriod, ".pdf");
                const value = await utility.printPdf(<StatementTemplate PDFData={PDFData} fileName={fileName} />, fileName);
                handleLoader(value);
            }).catch((err) => {
                showSnackbar(err?.message, false);
                handleLoader(false);
            });
    };

    const columns = [
        {
            name: t("pr.payment_period"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("pr.seller_name"),
            options: {
                filter: true,
                sort: false,
            },
        },
        {
            name: t("pr.oder_amount"),
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => ({ className: "text_right" })
            },
        },
        {
            name: t("pr.refund_amount"),
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({ className: "text_right" })
            },
        },
        {
            name: t("pr.amount_of_points_granted"),
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => ({ className: "text_right" })
            },
        },
        {
            name: t("pr.cainz_fee_amount"),
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => ({ className: "text_right" })
            },
        },
        {
            name: t("pr.payment_amount"),
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => ({ className: "text_right" })
            },
        },
        {
            name: t("pr.sales_statement"),
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => ({ className: "text_right" })
            },
        },


    ];

    const tableNegativeValue = (value: any) => {
        return (
            <Box className={`${value < 0 ? "sales_history_negative_quantity_red " : "sales_history_negative_quantity_black"}`}>{utility.numberWithCommas(value)}</Box>
        );
    };

    const PDFDownloadButton = (sellerCode: string, settlementPeriod: string, value: number) => {
        const updatedSettlementPeriod = settlementPeriod.replace("~", "-");
        return (
            <>
                <DownloadButton route={window.location.pathname} functionToExecute={() => downloadPDF(sellerCode, updatedSettlementPeriod)} className="table_btn" btnName={t("pr.pdf")} disableCondition={value <= 0 ? true : false} sx={{ float: "right" }} />
            </>
        );
    };

    const apiTableData = () => {
        {
            historyData?.map((item: any, index: number) => {
                apiData[index] = [];
                apiData[index].push(
                    item?.settlementPeriod,
                    item?.sellerName,
                    tableNegativeValue(item?.orderAmount),
                    tableNegativeValue(item?.refundAmount),
                    tableNegativeValue(item?.grantedPointAmount),
                    tableNegativeValue(item?.cainzFee),
                    tableNegativeValue(item?.transferAmount),
                    PDFDownloadButton(item?.sellerCode, item?.settlementPeriod, item?.transferAmount)
                );
            });
        }
    };
    apiTableData();

    const resetAllFilter = () => {
        setSearch("");
        setSellerValue("");
        setSellerName([]);
        setValueTo(null);
        setValueFrom(null);
        setResetData(true);
        setNoOptions(false);
        setFromParam("");
        setToParam("");
    };
    const shouldDisableDateTo = (date: any) => {
        if (valueFrom) {
            return date < valueFrom;
        }
        return false;
    };
    const shouldDisableDateFrom = (date: any) => {
        if (valueTo) {
            return date > valueTo;
        }
        return false;
    };

    useEffect(() => {
        forceUpdate(1);
    });

    const validatorCustomDate = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );

    const HandleDummy = (value: any) => {
        const [day, months, year] = value.toLocaleDateString("en-GB").split("/");
        setDateValue(`${year}-${months}-${day}`);
        setDummyValue(value);
    };

    const handleDummyData = () => {
        handleLoader(true);
        const payload = { date: dateValue.toString() };
        const isHitDummyURL = false;
        new SalesHistoryServices(payload, isHitDummyURL)
            .DummyUrlService()
            .then((res: any) => {
                showSnackbar(res, true);
                handleLoader(false);
                setTimeout(() => {
                    paymentHistoryData(currentPage, limit);
                }, 1000);
            })
            .catch((err: any) => {
                showSnackbar(err?.message, false);
                handleLoader(false);
            });
    };

    const handleDummyDataSubmit = () => {
        if (validatorCustomDate.current.allValid()) {
            handleDummyData();
        }
        else {
            validatorCustomDate.current.showMessages();
        }
    };

    return (
        <>
            <Container>
                <Grid container spacing={2}>
                    <Grid item lg={6}><PageTitle title={t("pr.payment_history")} /></Grid>
                    {
                        userType == CONSTANTS.USER_TYPE_SUPER_ADMIN ?
                            <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 4 }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={0} sm={0} md={0} lg={5}></Grid>
                                    <Grid item xs={12} sm={6} md={5} lg={3}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={jaLocale} dateFormats={{ monthAndYear: "yyyy年MM月" }}>
                                            <DatePicker
                                                className="date-picker-default input"
                                                label={t("pr.genarate_from")}
                                                value={dummyValue}
                                                format="yyyy/MM/dd"
                                                onChange={HandleDummy}
                                            />
                                            {validatorCustomDate.current.message("date-picker-default input", dummyValue, [
                                                "required",
                                            ])}
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid item xs={12} sm={5} md={6} lg={4}>
                                        <Button
                                            variant="contained"
                                            sx={{ textTransform: "capitalize", mt: 1, }}
                                            onClick={handleDummyDataSubmit}>
                                            {t("pr.payment_generate")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            : null
                    }
                </Grid>
            </Container>
            <Container sx={{ my: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
                        <FormControl fullWidth>
                            <Autocomplete
                                freeSolo={noOptions ? false : true}
                                disablePortal
                                id="combo-box-demo"
                                className="sellernameinput"
                                value={search}
                                onChange={(e: any, value: any) => handleSearchValue(e, value)}
                                onFocus={() => { setNoOptions(false); }}
                                options={sellerName}
                                forcePopupIcon={false}
                                noOptionsText="データが見つかりません"
                                disableClearable={true}

                                renderInput={(params: any) => <TextField {...params} label={t("pr.seller_name")} onChange={(e: any) => { handleSearchChange(e); }}
                                    onMouseEnter={(e: any) => handleSellerClick(e)} />}
                            />

                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja" dateFormats={{ monthAndYear: "YYYY年MM月" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <DatePicker
                                        className="from-date-seller-picker input"
                                        label={t("pr.from")}
                                        value={valueFrom}
                                        format="YYYY/MM/DD"
                                        onChange={handleDateFrom}
                                        // maxDate={valueTo}
                                        shouldDisableDate={shouldDisableDateFrom}
                                        sx={{ backgroundColor: "white", width: "100%" }} />
                                    {validator.current.message("valueFrom", valueFrom, [
                                        "required",
                                    ])}
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <DatePicker
                                        className="to-date-seller-picker input"
                                        label={t("pr.to")}
                                        format="YYYY/MM/DD"
                                        value={valueTo}
                                        onChange={handleDateTo}
                                        // minDate={valueFrom}
                                        shouldDisableDate={shouldDisableDateTo}
                                        sx={{ backgroundColor: "white", width: "100%" }} />

                                    {validator.current.message("valueTo", valueTo, [
                                        "required",
                                    ])}
                                </Grid>
                            </Grid>
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3} sx={{ mt: 1 }}>
                        <Button
                            variant="contained"
                            className="submit_btn"
                            sx={{ textTransform: "capitalize" }}
                            onClick={handleSubmit}>
                            {t("pr.submit")}

                        </Button>
                        <Button
                            variant="contained"
                            className="submit_btn"
                            sx={{ textTransform: "capitalize", ml: 2 }}
                            onClick={resetAllFilter}> {t("pr.reset")}
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <DownloadButton loading={loading} route={window.location.pathname} functionToExecute={downloadPaymentHistoryData} className="payment_history_download_btn" btnName={t("pr.download")} disableCondition={historyData?.length == 0 || loading} />
                    </Grid>
                </Grid>

            </Container>
            <>
                <div className="main">
                    {skeleton ? (
                        <Skeleton
                            varient="rectangular"
                            width={0}
                            height={60}
                            count="10"
                            my={0}
                        />
                    ) : (

                        <Container sx={{ my: 4 }} className="minimum_column no_data_text_align_center sales_history_first_table_cell_small_width ">
                            <MUIDataTable
                                title={""}
                                columns={columns}
                                data={apiData}
                                options={{
                                    pagination: false,
                                    print: false,
                                    download: false,
                                    filter: false,
                                    viewColumns: false,
                                    search: false,
                                    selectableRowsHideCheckboxes: true,
                                    textLabels: {
                                        body: {
                                            noMatch: t("table_text.no_match").toString(),
                                        },
                                        viewColumns: {
                                            title: t("table_text.show_columns").toString(),
                                            titleAria: "Show/Hide Table Columns",
                                        },
                                        toolbar: {
                                            search: t("table_text.search").toString(),
                                            viewColumns: t("table_text.view_columns").toString(),
                                        },
                                    },
                                    searchPlaceholder: t("table_text.search_placeholder").toString(),
                                    responsive: "standard",

                                }}
                            />
                        </Container>
                    )}
                </div>
            </>

            <Pagination
                totalCount={totalCount}
                currentPage={currentPage}
                pageChange={paymentHistoryData}
                totalPage={totalPages}
                rowsPerPage={limit}
                rowsPerPageChange={setLimit}
            />
        </>

    );
};

export default PaymentHistory;
