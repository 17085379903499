export const mockAPIPath = {
    ADMINUSERS : "./data/adminUsers.json",
    ENABLE_DISABLE_USER : "",
    UPDATE_USER: "./data/updateUser.json",
    UPDATE_EMAIL: "./data/updateEmail.json",
    USER_DETAILS: "./data/adminUsers.json",
    ADMIN_USER_ROLE:"./data/adminUserRole.json",
    SELECT_USER_ROLE:"./data/rmsDelete.json"

};

export const serverAPIPath = {
    ADMINUSERS : "/user/admins",
    ENABLE_DISABLE_USER : "/user/admin-status/",
    REINVITE_USER: "/invite/user",
    DELETE_INVITED_USER: "/invite/user-list/",
    USER_DETAILS: "/user/admins/",
    UPDATE_EMAIL: "/user/email",
    UPDATE_NAME: "/user/edit-profile",
    DELETE_ADMIN:"/user/admins/",
    EMAIL_VERIFY: "/user/is-email-available",
    UPDATE_USER: "",
    ADMIN_USER_ROLE:"/rms/roles",
    SELECT_USER_ROLE:"/rms/roles/assign"
};