import { API } from "../../../config";
import { LIST_LIMIT } from "../../../constants/constants";
import { CategoryListWithPaginationType } from "../../../interfaces/categoryListInterface";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";

class CategoryListService {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;
    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }
    async categoryList(
        page_id: number,
        limit = LIST_LIMIT.CATEGORY_LIST_LIMIT,
        searchByCategoryCode:string,
        postingStatus?:any,
        level?:any, 
        direction? : any,
        column? : any
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.CATEGORY_LIST}`,
                this.payload
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.CATEGORY_LIST}?page=${page_id}&limit=${limit}&search=${searchByCategoryCode}&postingStatus=${postingStatus}&categoryLevel=${level}&order=${direction}&column=${column}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
            const { currentPage, totalPages, currentTotalCount, totalCount} = response.data.data;
            const CategoryListData: CategoryListWithPaginationType  = {
                categoryListLogs: response.data.data.categories,
                pagination: {
                    totalPages,
                    currentPage,                  
                    currentTotalCount,
                    totalCount,
                },
                lastUpdatedAt : response.data.data?.lastUpdatedAt
            };
            return CategoryListData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async categoryListDownload(
        page_id: number,
        limit = LIST_LIMIT.CATEGORY_LIST_LIMIT,
        searchByCategoryCode:string,
        postingStatus?:any,
        level?:any, 
        direction? : any,
        column? : any
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.CATEGORY_LIST}`,
                this.payload
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.CATEGORY_LIST}?page=${page_id}&limit=${limit}&search=${searchByCategoryCode}&postingStatus=${postingStatus}&categoryLevel=${level}&order=${direction}&column=${column}&download=true`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
            const { currentPage, totalPages, currentTotalCount, totalCount} = response.data.data;
            const CategoryListData: CategoryListWithPaginationType  = {
                categoryListLogs: response.data.data.categories,
                pagination: {
                    totalPages,
                    currentPage,                  
                    currentTotalCount,
                    totalCount,
                },
            };
            return CategoryListData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async updateCategory(){
        const response = this.isHitDummyURL
            ? await new AxiosUtils(`${mockAPIPath.UPDATE_CATEGORY}`, this.payload).put()
            : await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.UPDATE_CATEGORY}`, this.payload, this.headers).put();
        if (response?.data?.success) {
            return response?.data;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }
}

export default CategoryListService;