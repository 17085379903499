import React, { useEffect, useRef, useState } from "react";
import { Container, Grid, TextField, Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import SimpleReactValidator from "simple-react-validator";
import Typography from "@mui/material/Typography";
import { ROUTES } from "../../../../constants/routes";
import OTPService from "../../../Auth/service/OTPService";
import AdminUserListService from "../service/AdminUserListService";
import CONSTANTS from "../../../../constants/constants";

const UpdateEmail = (props: any) => {
    const { showSnackbar, handleLoader } = props;

    /* Language translate function*/
    const [t] = useTranslation();
    /* Language translate function*/

    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const error = "" ;

    // validation
    const [, forceUpdate] = useState(0);

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                email: t("update_email.please_enter_valid_email"),
                max: t("invitation_page.max_80_char"),
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );

    useEffect(() => {
        forceUpdate(1);
    });

    const handleOnBlur = (e: any) => {
        const { name } = e.target;

        validator.current.showMessageFor(name);
    };

    // validation

    const handleChange = (e: any) => {
        const { name, value } = e.target;

        name === "email" ? setEmail(value) : null;

        validator.current.hideMessageFor(name);
    };

    const generateOTP = () => {
        const payload = { email, otpType : CONSTANTS.OTP_EMAIL_TYPE };
        const isHitDummyURL = false;
        new OTPService(payload, isHitDummyURL)
            .generate()
            .then((res) => {
                handleLoader(false);
                showSnackbar(res, true);
                navigate(ROUTES.EMAIL_OTP, { state: { email: email } });
            })
            .catch((err) => showSnackbar(err?.message, false));
    };

    const handleSubmit = () => {
        if (validator.current.allValid()) {
            handleLoader(true);
            const payload = { email };
            const isHitDummyURL = false;
            new AdminUserListService(payload, isHitDummyURL)
                .availableEmail()
                .then((res) => {
                    handleLoader(false);
                    showSnackbar(res.message, true);
                    generateOTP();
                })
                .catch((err) => {
                    handleLoader(false);
                    showSnackbar(err?.message, false);
                });
            
        } else {
            validator.current.showMessages();
        }
    };


    return (
        <>
            <div className="main">
                <Container>
                    <Grid container spacing={4}>
                        {/* offset grid with column*/}
                        <Grid item xs={12} xl={4} lg={4} md={2} sm={12}></Grid>
                        {/* main grid column! */}
                        <Grid item xs={12} xl={4} lg={4} md={8} sm={12}>
                            <Box className="mt_300">
                                <Grid item xs={12} md={12}>
                                    <Typography
                                        variant="h6"
                                        className="text_center"
                                        sx={{ py: 2 }}
                                    >
                                        {t("update_email.email_address")}
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        type="email"
                                        value={email}
                                        name="email"
                                        label={t("update_email.email_address")}
                                        variant="outlined"
                                        onChange={handleChange}
                                        error={error ? true : false}
                                        helperText={error}
                                        onBlur={(e) => handleOnBlur(e)}
                                    />
                                    {validator.current.message("email", email, [
                                        "required",
                                        { max: 80 },
                                        "email",
                                    ])}
                                    <Grid item xs={12} md={12} sx={{ my: 2 }}>
                                        <Button fullWidth onClick={handleSubmit}>
                                            {t("update_email.next")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    );
};

export default UpdateEmail;
