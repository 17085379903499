import CryptoJS from "crypto-js";
import { snackbarActions } from "../redux/features/snackbar/snackbarSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";

/**
 * @export Crypt
 * @description The Utility is responsible for the operations to encrypt and decrypt.
 */

export class Crypt {
    decryptedValue(payeeFinancialInstitutionCode: any, iv: string, index: any): any {
        throw new Error("Method not implemented.");
    }
    private cryptoMessage= useAppSelector(state => state.snackbar.snackbarInfo);
    private key;
    // private iv;
    private mode;
    private keySize;
    private padding;
    private dispatch = useAppDispatch();
    private setError;

    constructor(state: any) {
        this.key = CryptoJS.SHA256(process.env.REACT_APP_ENCRYPTION_KEY ?? "");
        // this.iv = CryptoJS.SHA256(process.env.ENCRYPTION_IV ?? "");
        this.mode = CryptoJS.mode.CBC;
        this.padding = CryptoJS.pad.Pkcs7;
        this.keySize = 32;
        this.setError = state;
    }

    /**
     * @description Function is reponsible to encrypt data based on it IV
     * @param {string} plainText 
     * @param {string} iv (initialized vector) 
     * @returns {string} decrypted data
     */
    encryptWithCryptoJS = (checkText:any,iv:any, skip:number) => {
        // if(!plainText && skip ){
        //     return "";
        // }
        let plainText;

        if (!checkText){
            plainText = "";
        }else{
            plainText = checkText.toString();
        }


        const key = this.key;
        iv = CryptoJS.SHA256(iv);
        // const iv = this.iv;
        const encrypted = CryptoJS.AES.encrypt(plainText, key, {
            keySize: this.keySize,
            iv: iv,
            mode: this.mode,
            padding: this.padding
        });
        // return encrypted + "";
        return encrypted.toString();
    };

    /**
     * @description Function is reponsible to decrypt data based on it IV
     * @param {string} plainText 
     * @param {string} iv (initialized vector) 
     * @returns {string} encrypted data
     */
    decryptWithCryptoJS = (cipher:any,iv:any, id:number) => {
        try{
            const emptyEncryption = this.encryptWithCryptoJS("", iv, 0);
            if(!cipher || cipher == emptyEncryption){
                return "";
            }
            const key = this.key;
            iv = CryptoJS.SHA256(iv);
            // const iv = this.iv;
            const plainText = CryptoJS.AES.decrypt(cipher, key, {
                keySize: this.keySize,
                iv: iv,
                mode: this.mode,
                padding: this.padding
            });

            let decodedData = true;
            decodedData = plainText?.toString(CryptoJS.enc.Utf8) ? true:false;
            if(decodedData==false){
                if(!this.cryptoMessage.show){
                    this.setError(id);
                    this.dispatch(
                        snackbarActions.SHOW_SNACKBAR({
                            show: true,
                            message: "一部のデータが正しく暗号化されていません",
                            success: false,
                            autoClose: true
                        })
                    );
                }
                return cipher;

            }else{
                return plainText?.toString(CryptoJS.enc.Utf8);
            }
        }
        catch(error:any){
            //  
        }
       
    };



    checkDecryptWithCryptoJS = (cipher:any,iv:any, id:number, isInitialValue:boolean) => {
        try{
            const emptyEncryption = this.encryptWithCryptoJS("", iv, 0);
            if(cipher == emptyEncryption){
                return false;
            }
            const key = this.key;
            iv = CryptoJS.SHA256(iv);
            // const iv = this.iv;
            const plainText = CryptoJS.AES.decrypt(cipher, key, {
                
                keySize: this.keySize,
                iv: iv,
                mode: this.mode,
                padding: this.padding
            });
        
            
            let decodedData = true;
            decodedData = plainText?.toString(CryptoJS.enc.Utf8) ? true : false;

            if(decodedData==false){
                // Data decrypted found
                return true;
            }else{
                // Data encrypted found
                return false;
            }

        }
        catch(error:any){
        
            // if(!isInitialValue){

            //     if(!this.cryptoMessage.show){
            //         this.setError(id);
            //         this.dispatch(
            //             snackbarActions.SHOW_SNACKBAR({
            //                 show: true,
            //                 message: "一部のデータが正しく暗号化されていません",
            //                 success: false,
            //                 autoClose: true
            //             })
            //         );
            //     }
            // }
            return true;
        }
       
    };

}