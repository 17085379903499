import { API } from "../../../config";
import { LIST_LIMIT } from "../../../constants/constants";
import { PayeeTableLogWithPaginationType } from "../../../interfaces/payeeInfoInterface";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";

class PayeeTableService {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async payeetList(
        page: number,
        limit = LIST_LIMIT.ENCRYPTION_DECRYPTION_LIMIT,
        orderId:string,
        sellerCode:string,
        colName?:string,
        order?:string,
        statusFilter?:any   
        
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.PAYEE_TABLE}`,
                this.payload
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.PAYEE_TABLE}?page=${page}&limit=${limit}&orderId=${orderId}&sellerCode=${sellerCode}&column=${colName}&order=${order}&status=${statusFilter}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
           
            const { data, currentPage, totalPages, currentTotalCount, totalCount } = response.data;
            const TransactionsData: PayeeTableLogWithPaginationType = {
                payeeInfoLogs: data,
                pagination: {
                    totalPages,
                    currentPage,                  
                    currentTotalCount,
                    totalCount,
                },
            };
            return TransactionsData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async downloadPayeetList(
        orderId:string,
        sellerCode:string,
        colName?:string,
        order?:string,
        statusFilter?:any 
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.PAYEE_TABLE}`,
                this.payload
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.PAYEE_TABLE}?&orderId=${orderId}&sellerCode=${sellerCode}&column=${colName}&order=${order}&status=${statusFilter}&download=true`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
           
            const { data, currentPage, totalPages, currentTotalCount, totalCount } = response.data;
            const TransactionsData: PayeeTableLogWithPaginationType = {
                payeeInfoLogs: data,
                pagination: {
                    totalPages,
                    currentPage,                  
                    currentTotalCount,
                    totalCount,
                },
            };
            return TransactionsData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async downloadOrderHeader()
    {
        const response = this.isHitDummyURL ? await new AxiosUtils(`${mockAPIPath.ORDER_TABLE_HEADER}`,  this.payload ).get()
            : await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.ORDER_TABLE_HEADER}?`,  this.payload, this.headers ).get(); 
           
        if (response?.data?.success) {
            // const { data} = response.data;
            return response.data;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }
    
}

export default PayeeTableService;