class PaginationModel {
    private _totalPages!: number;
    private _currentPage!: number;
    private _currentTotalCount!: number;
    private _totalCount!: number;
 
    public static parseJson(paginationData: PaginationModel) {
        const paginationItem: PaginationModel = new PaginationModel();
        paginationItem.totalPages = paginationData.totalPages;
        paginationItem.currentPage = paginationData.currentPage;
        paginationItem.currentTotalCount = paginationData.currentTotalCount;
        paginationItem.totalCount = paginationData.totalCount;
        
        return paginationItem;
    }

    public get totalPages() {
        return this._totalPages;
    }
    public set totalPages(totalPages: number) {
        this._totalPages = totalPages;
    }

    public get currentPage() {
        return this._currentPage;
    }
    public set currentPage(currentPage: number) {
        this._currentPage = currentPage;
    }

    public get currentTotalCount() {
        return this._currentTotalCount;
    }
    public set currentTotalCount(currentTotalCount: number) {
        this._currentTotalCount = currentTotalCount;
    }
    public get totalCount() {
        return this._totalCount;
    }
    public set totalCount(totalCount: number) {
        this._totalCount = totalCount;
    }

}

export default PaginationModel;