import { API } from "../../../config";
import { LIST_LIMIT } from "../../../constants/constants";
import { PaymentReportLogWithPaginationType } from "../../../interfaces/PaymentReportNameInterface ";
import { TransactionLogWithPaginationType } from "../../../interfaces/transactionInterface";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";

class TransactionService {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async transactionList(
        page: number,
        limit = LIST_LIMIT.STORE_LIST_LIMIT,
        search:string,
        transactionType:string,
        transactionStatus:string,
        pastNoOfDays:string,
        toParam="",
        fromParam:string,
        settlementPeriod:string,
        depositDate:string,
        orderId:string, 
        colName?:string,
        order?:string
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.TRANSACTION_LIST}`,
                this.payload
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.TRANSACTION_LIST}?page=${page}&limit=${limit}&seller=${search}&transactionType=${transactionType}&transactionStatus=${transactionStatus}&days=${pastNoOfDays}&to=${toParam}&from=${fromParam}&settlementPeriod=${settlementPeriod}&paymentDay=${depositDate}&search=${orderId}&column=${colName}&order=${order}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {
           
            const { data, currentPage, totalPages, currentTotalCount, totalCount, configurationDate,firstSalesDate } = response.data.data;
            const TransactionsData: TransactionLogWithPaginationType = {
                transactionsLogs: data,
                configurationDate:configurationDate,
                firstSalesDate:firstSalesDate,
                pagination: {
                    totalPages,
                    currentPage,                  
                    currentTotalCount,
                    totalCount,
                },
            };
            return TransactionsData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async PaymentReportSearchSellerName(
        search: string
    )
    {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.PAYMENT_REPORT_SELLER_NAME}`,
                this.payload
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.PAYMENT_REPORT_SELLER_NAME}?search=${search}`,
                this.payload,
                this.headers
            ).get();
      
        if (response?.data?.success) {
            const { data } = response.data.data;
            const PaymentReportNameData: PaymentReportLogWithPaginationType = {
                paymentReportNameLogs: data,
            };
            return PaymentReportNameData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async downloadPaymentRepoart(
        search:string,
        transactionType:string,
        transactionStatus:string,
        pastNoOfDays:string,
        toParam:string,
        fromParam:string,
        settlementPeriod:string,
        depositDate:string,
        orderId:string,
        colName?:string,
        order?:string
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.TRANSACTION_LIST}`,
                this.payload
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.TRANSACTION_LIST}?seller=${search}&transactionType=${transactionType}&transactionStatus=${transactionStatus}&days=${pastNoOfDays}&to=${toParam}&from=${fromParam}&settlementPeriod=${settlementPeriod}&paymentDay=${depositDate}&search=${orderId}&download=true&column=${colName}&order=${order}`,
                this.payload,
                this.headers
            ).get();
      
        if (response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount, configurationDate } =   response.data.data;   
            const TransactionsData: TransactionLogWithPaginationType  = {
                transactionsLogs: data,
                configurationDate:configurationDate,
                pagination: {
                    currentPage,
                    totalPages,
                    currentTotalCount,
                    totalCount,
                },
            };
            return TransactionsData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }



}

export default TransactionService;