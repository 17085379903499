import React, { FC } from "react";
import { Container, Grid, Typography } from "@mui/material";
interface headerProps {
  title: string;
}
const PageTitle: FC<headerProps> = (props: headerProps): JSX.Element => {
    return (
        <>
            <Container sx={{ py: 4 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ py: 2 }}>
                    <Typography variant="h4">{props.title}</Typography>
                </Grid>
                
                <Grid item xs={2} sm={2} md={2} lg={2} sx={{ py: 2 }}>
                    <Typography
                        sx={{ borderBottom: "3px solid red", width: "50px" }}
                    ></Typography>
                </Grid>
               
               
            </Container>
        </>
    );
};

export default PageTitle;
