export const mockAPIPath = {
    SYSTEM_CONFIGURATION_UPDATE:"./data/systemConfigurationUpdate.json",
    SYSTEM_CONFIGURATION:"./data/systemConfiguration.json",
};

export const serverAPIPath = {
    SYSTEM_CONFIGURATION_UPDATE:"/master/system-config",
    SYSTEM_CONFIGURATION: "/master/system-config",
};





