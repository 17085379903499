import React, { useEffect, useState } from "react";
import {
    Container,
    Grid,
    Button,
    TextField,
    InputAdornment,
    IconButton,
    Box,
    Modal,
    Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useTranslation } from "react-i18next";
import StoreService from "./service/StoreService";
import CONSTANTS, { LIST_LIMIT, USER_LIST_CONSTANTS } from "../../constants/constants";
import Pagination from "../common/Pagination";
import PageTitle from "../common/PageTitle";
import StoreListPaginationModel from "../../models/StoreList/storeListPaginationModel";
import Skeleton from "../common/Skeleton";
import SearchIcon from "@mui/icons-material/Search";
import StoreModel from "../../models/StoreList/storeModel";
import { ROUTES } from "../../constants/routes";
import MUIDataTable from "mui-datatables";
import { API } from "../../config";
import Cookies from "../../utils/Cookies";
import sign from "jwt-encode";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import lazyTypingUtils from "../../utils/lazyTypingUtils";
import { snackbarActions } from "../../redux/features/snackbar/snackbarSlice";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CommonButton from "../common/Button";

const Store = (props: any) => {
    const { showSnackbar, handleLoader } = props;

    const [t] = useTranslation();
    const dispatch = useAppDispatch();

    const pathName = window.location.pathname;
    
    const perPageLimit = useAppSelector((state) => state.common.perPageLimit);

    const [limit, setLimit] = useState(!perPageLimit ? LIST_LIMIT.STORE_LIST_LIMIT : perPageLimit );
    const [isReload, setIsReload] = useState(false);
    const [storeListWithPagination, setStoreListWithPagination] =
    useState<StoreListPaginationModel>();
    const [currentPage, setCurrentPage] = useState(1);
    localStorage.setItem(pathName, currentPage.toString());
    const firstPage = Number(localStorage.getItem(pathName));
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [skeleton, setSkeleton] = useState(true);
    const [search, setSearch] = useState<string>("");
    const [deleteUserId, setDeleteUserId] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    // const [inviteModalOpen, setInviteModalOpen] = useState(false);
    // const [copyUrl, setCopyUrl] = useState("");
    // const [url, setUrl] = useState("");
    const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
    const [selectedSellerDetails, setSelectedSellerDetails] = useState<any>();
    const [isStoreVisited, setIsStoreVisited] = useState(false);
    const [isSearchChange,setIsSearchChange]=useState<boolean>(false);
    const [hideColumnArr,setHideColumnArr] = useState<any>([]);
    const [colFilter,setColFilter] =useState<string>("");
    const [direction,setDirection]=useState<string>("");
    const [colName,setColName]=useState<string>("");

    const apiData: any[][] = [];

    // const navigate = useNavigate();

    const loggedInUserType = useAppSelector(
        (state) => state.permission.permissionDetails.userType
    );

    useEffect(() => {
        if (isStoreVisited || colFilter || direction) {
            storeList(firstPage, limit);
        }
    }, [isStoreVisited,colFilter,direction]);

    const onsearchApicall = () =>{
        if(isSearchChange){
            storeList(CONSTANTS.FIRST_PAGE, limit);
        }
    };
    
    const propItem = [
        onsearchApicall,search
    ];
    lazyTypingUtils(propItem);

    // useEffect(() => {
    //     if (url != "") {
    //         const copyUrl = url;
    //         setCopyUrl(copyUrl);
    //         setInviteModalOpen(!modalOpen);
    //     }
    // }, [url]);

    const onTabFocus = () => {
        setIsStoreVisited(true);
    };

    const onTabBlur = () => {
        setIsStoreVisited(false);
    };

    useEffect(() => {
        window.addEventListener("focus", onTabFocus);
        window.addEventListener("blur", onTabBlur);
        onTabFocus();
        return () => {
            window.removeEventListener("focus", onTabFocus);
            window.removeEventListener("blur", onTabBlur);
        };
    }, []);

    const storeList = (currentPage: number, limit: any) => {
        setIsSearchChange(false);
        setSkeleton(true);
        const isHitDummyURL = false;
        new StoreService({}, isHitDummyURL)
            .storeList(currentPage, search.trim(), limit,colFilter,direction)
            .then((res) => {
                setCurrentPage(currentPage);
                setLimit(limit);
                setStoreListWithPagination(res);
                setTotalCount(res.pagination.totalCount);
                setTotalPages(res.pagination.totalPages);
                setSkeleton(false);
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
                setSkeleton(false);
            });
    };

    const handleReinviteAdmin = (email: string) => {
        handleLoader(true);
        const payload = { email: email, userType: CONSTANTS.USER_TYPE_SELLER };
        const isHitDummyURL = false;
        new StoreService(payload, isHitDummyURL)
            .reInviteAdmin()
            .then((res) => {
                handleLoader(false);
                showSnackbar(res.message, true);
                // setUrl(res.data.url);
            })
            .catch((err) => {
                handleLoader(false);
                showSnackbar(err?.message, false);
            });
    };

    const deleteInvitation = (invitationId: number) => {
        handleLoader(true);
        const isHitDummyURL = false;
        new StoreService({}, isHitDummyURL)
            .deleteAdmin(invitationId)
            .then((res) => {
                storeList(currentPage, limit);
                handleLoader(false);
                setIsReload(!isReload);
                showSnackbar(res, true);
                closeModal();
            })
            .catch((err) => {
                handleLoader(false);
                showSnackbar(err?.message, false);
                closeModal();            
            });
    };

    const deleteModalOpen = (UserId: number) => {
        setModalOpen(true);
        setDeleteUserId(UserId);
    };

    const closeModal = () => {
        setModalOpen(false);
        //setInviteModalOpen(false);
        // setUrl("");
    };

    useEffect(() => {  
        dispatch(snackbarActions.HIDE_SNACKBAR(false));
    },[]);

    const navigateInvitePage = () => {
        localStorage.setItem("userType", CONSTANTS.USER_TYPE_SELLER.toString());
        open(ROUTES.INVITE_PAGE, "_blank");
    };

    const handleSearchChange = (e: any) => {
        setIsSearchChange(true);
        const { value } = e.target;
        setSearch(value);
    };

    const redirectToSellerPanel = () => {
        const { storeID, userID } = selectedSellerDetails;
        const accessToken = new Cookies("access-token").read();
        const code = sign({
            accessToken,
            storeID,
            userID
        }, CONSTANTS.JWT_SECRET_KEY);
        window.open(`${API.ADMIN_AUTH_SELLER_PANEL_URL}?code=${code}`, "_blank");
    };

    const actionButton = (
        email: string,
        invitationId: number,
        status: number,
        userID: number,
        storeID: number,
    ) => {
        return (
            <>
                <Box className="table_btn">
                    <CommonButton route={window.location.pathname} functionToExecute={() => handleReinviteAdmin(email)} className="table_btn" btnName={t("stock_page.reinvite")} disableCondition={status == 1 ? true : false}/>
                    {/* <Button
                        className="table_btn"
                        variant="contained"
                        disabled={status == 1 ? true : false}
                        onClick={() => handleReinviteAdmin(email)}
                    >
                        {t("stock_page.reinvite")}
                    </Button> */}
                    <Button 
                        className="table_btn"
                        variant="contained" 
                        onClick={() => {
                            setSelectedSellerDetails({
                                userID,
                                storeID
                            });
                            handleShowDetailClick();
                        }} 
                        disabled={status == USER_LIST_CONSTANTS.STATUS_ACCEPTED && storeID ? false : true}
                    >
                        {t("stock_page.detail")}
                    </Button>
                    <CommonButton route={window.location.pathname} functionToExecute={() => deleteModalOpen(invitationId)} className="table_btn" btnName={t("stock_page.delete")} disableCondition={(loggedInUserType != CONSTANTS.USER_TYPE_SUPER_ADMIN ? true : false) && (status == 1 ? true : false)}/>
                    {/* <Button
                        className="table_btn"
                        variant="contained"
                        disabled={(loggedInUserType != CONSTANTS.USER_TYPE_SUPER_ADMIN ? true : false) && (status == 1 ? true : false)}
                        onClick={() => deleteModalOpen(invitationId)}
                    >
                        {t("stock_page.delete")}
                    </Button> */}
                </Box>
            </>
        );
    };

    const apiTableData = () => {
        {
            storeListWithPagination?.store?.map((item: StoreModel, index: number) => {
                const status = item.status == USER_LIST_CONSTANTS.STATUS_PENDING ? t("stock_page.inviting") :
                    item.status == USER_LIST_CONSTANTS.STATUS_EXPIRED ? t("stock_page.disabled") : t("stock_page.accepted");
                apiData[index] = [];
                apiData[index].push(
                    (currentPage - 1) * limit + index + 1,
                    item?.storeCode,
                    item?.corporateName,
                    item?.storeName,
                    item?.name,
                    item?.email,
                    status,
                    actionButton(item.email, item.invitationId, item.status, item.userId, item.storeId)
                );
            });
        }
    };
    
    apiTableData();

    
    const handleSorting =(changedColumn:any)=>{
        if(colName!=changedColumn){
            setDirection("asc");

        }else{
            setDirection(direction=="asc"? "desc":"asc");
        }
        
        setColName(changedColumn);   
        const columnName=changedColumn == "Store Code" || changedColumn=="ストアコード" ? "store_code": changedColumn == "Corporation Name" || changedColumn == "法人名" ? "corporate_name": changedColumn == "Store Name" || changedColumn == "ストア名" ? "store_name":"";
          
        setColFilter(columnName);

    };

    const columns = [
        {
            name: t("stock_page.s_no"),
            options: {
                filter: true,
                sort: false,
                display: hideColumnArr.includes("No.") ? false : true,
            },
        },
        {
            name: t("stock_page.store_code"),
            options: {
                filter: false,
                sort: false,
                display: hideColumnArr.includes("ストアコード") ? false : true,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box>
                            {data.label}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="store_code" ? <SwapVertIcon className="swapVertIcon"/> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition" />:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>  
                        </Box>
                    );
                },
            },
        },
        {
            name: t("stock_page.corp_name"),
            options: {
                filter: false,
                sort: false,
                display: hideColumnArr.includes("法人名") ? false : true,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box>
                            {data.label}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="corporate_name" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>   
                        </Box>
                    );
                },    
            },
        },
        {
            name: t("stock_page.store_name"),
            options: {
                filter: true,
                sort: false,
                display: hideColumnArr.includes("ストア名") ? false : true,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box>
                            {data.label}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="store_name" ? <SwapVertIcon className="swapVertIcon" /> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton> 
                        </Box>
                    );
                },  
            },
        },
        {
            name: t("stock_page.authorized_person_name"),
            options: {
                filter: true,
                sort: false,
                display: hideColumnArr.includes("出品代表者名") ? false : true,
            },
        },
        {
            name: t("stock_page.email"),
            options: {
                filter: true,
                sort: false,
                display: hideColumnArr.includes("メールアドレス") ? false : true,
            },
        },
        {
            name: t("stock_page.status"),
            options: {
                filter: true,
                sort: false,
                display: hideColumnArr.includes("ステータス") ? false : true,
            },
        },
        {
            name: t("stock_page.action"),
            options: {
                filter: true,
                sort: false,
                display: hideColumnArr.includes("アクション") ? false : true,
            },
        },
    ];

    const handleShowDetailClick = () => {
        setShowDetailModal(true);
    };

    const handleShowDetailModalYes = async () => {
        setShowDetailModal(false);
        handleLoader(true);
        await new StoreService().refreshAccessToken();
        handleLoader(false);
        redirectToSellerPanel();
    };

    const handleShowDetailModalNo = () => {
        setShowDetailModal(false);
        setSelectedSellerDetails(null);
    };

    return (
        <>
            <div className="main">
                <Container>
                    <Grid container spacing={0} sx={{ py: 5 }}>
                        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ py: 2 }}>
                            <PageTitle title={t("stock_page.stock_list")} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ py: 6 }}>
                            <CommonButton route={window.location.pathname} functionToExecute={navigateInvitePage} className="store_invite_btn" btnName={t("stock_page.invite_user")} disableCondition={false} startIconRequired={<AddCircleIcon />}/>
                            {/* <Button
                                className="float_right"
                                size="small"
                                sx={{ py: 2 }}
                                startIcon={<AddCircleIcon />}
                                onClick={navigateInvitePage}
                            >
                                {t("stock_page.invite_user")}
                            </Button> */}
                        </Grid>
                    </Grid>
                </Container>

                <Container className="store_table  first_table_cell_small_width">

                    <Grid container spacing={0} sx={{ py: 1 }}>
                        <Grid item xs={12} sm={6} md={4} lg={3} sx={{ py: 1, px: 2 }}>
                            <TextField
                                id="input-with-icon-textfield"
                                label={t("stock_page.search_place_holder")}
                                onChange={handleSearchChange}
                                value={search}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    {skeleton ? (
                        <Skeleton
                            varient="rectangular"
                            width={0}
                            height={60}
                            count="10"
                            my={0}
                        />
                    ) : (
                        <>

                            <MUIDataTable
                                title={""}
                                columns={columns}
                                data={apiData}
                                options={{
                                    pagination: false,
                                    print: false,
                                    download: false,
                                    filter: false,
                                    search: false,
                                    selectableRowsHideCheckboxes: true,
                                    // sortOrder: {
                                    //     name: colName,
                                    //     direction: direction =="desc"? "desc":"asc",
                                    // },
                                    textLabels: {
                                        body: {
                                            noMatch: t("table_text.no_match").toString(),
                                        },
                                        viewColumns: {
                                            title: t("table_text.show_columns").toString(),
                                            titleAria: "Show/Hide Table Columns",
                                        },
                                        toolbar: {
                                            search: t("table_text.search").toString(),
                                            viewColumns: t("table_text.view_columns").toString(),
                                        },
                                    },
                                    searchPlaceholder: t(
                                        "table_text.search_placeholder"
                                    ).toString(),
                                    responsive: "standard",
                                    onSearchChange: (searchText: any) => {
                                        setSearch(searchText ? searchText : "");
                                    },
                                    onViewColumnsChange: (changedColumn:string,action:string) => {
                                        const tempHideColArr=[...hideColumnArr];
                                        if(action==="remove"){
                                            changedColumn=="Store Code"?
                                                tempHideColArr.push("ストアコード"):
                                                changedColumn=="Corporation Name"?
                                                    tempHideColArr.push("法人名"):
                                                    changedColumn=="Store Name"?
                                                        tempHideColArr.push("ストア名"):
                                                        changedColumn=="Exhibition Name"?
                                                            tempHideColArr.push("出品代表者名"):
                                                            changedColumn=="Email Address"?
                                                                tempHideColArr.push("メールアドレス"):
                                                                changedColumn=="Status"?
                                                                    tempHideColArr.push("ステータス"):
                                                                    changedColumn=="Action"?
                                                                        tempHideColArr.push("アクション"):                                              
                                                                        tempHideColArr.push(changedColumn);
                                            setHideColumnArr(tempHideColArr);
                                        }
                                        else{
                                            let index =-1;
                                            changedColumn=="Store Code"?
                                                index = tempHideColArr.indexOf("ストアコード"):
                                                changedColumn=="Corporation Name"?
                                                    index = tempHideColArr.indexOf("法人名"):
                                                    changedColumn=="Store Name"?
                                                        index = tempHideColArr.indexOf("ストア名"):
                                                        changedColumn=="Exhibition Name"?
                                                            index = tempHideColArr.indexOf("出品代表者名"):
                                                            changedColumn=="Email Address"?
                                                                index = tempHideColArr.indexOf("メールアドレス"):
                                                                changedColumn=="Status"?
                                                                    index = tempHideColArr.indexOf("ステータス"):
                                                                    changedColumn=="Action"?
                                                                        index = tempHideColArr.indexOf("アクション"):

                                                                        index = tempHideColArr.indexOf(changedColumn);
                                            tempHideColArr.splice(index,1);
                                            setHideColumnArr(tempHideColArr);
                                        }
                                    }  
                                    // onColumnSortChange: (changedColumn, direction) => {
                                    //     setDirection(direction);
                                    //     setColName(changedColumn);               
                                    //     const columnName=changedColumn == "Store Code" || changedColumn=="ストアコード" ? "store_code": changedColumn == "Corporation Name" || changedColumn == "法人名" ? "corporate_name": changedColumn == "Store Name" || changedColumn == "ストア名" ? "store_name":"";
                                    //     setColFilter(columnName);
                                    // },
                                }}
                            />
                        
                        </>
                    )}
                    <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        pageChange={storeList}
                        totalPage={totalPages}
                        rowsPerPage={limit}
                        rowsPerPageChange={setLimit}
                    />
                </Container>
                <Modal
                    open={showDetailModal}
                    aria-labelledby="delete-modal-title"
                    aria-describedby="delete-modal-description"
                >
                    <Box className="center-model" sx={{ p: 4 }}>
                        <Typography
                            id="delete-modal-title"
                            variant="h6"
                            component="h2"
                        ></Typography>
                        <Typography id="delete-modal-description" sx={{ m: 2 }}>
                            {t("stock_page.open_store_management_screen_message")}
                        </Typography>
                        <Button id="openWindowButton" sx={{ m: 2, p: 1 }} onClick={handleShowDetailModalYes}>
                            {t("invited_user_list.yes")}
                        </Button>
                        <Button sx={{ m: 2, p: 1 }} onClick={handleShowDetailModalNo}>
                            {t("invited_user_list.no")}
                        </Button>
                    </Box>
                </Modal>
                <Modal
                    open={modalOpen}
                    onClose={closeModal}
                    aria-labelledby="delete-modal-title"
                    aria-describedby="delete-modal-description"
                >
                    <Box className="center-model" sx={{ p: 4 }}>
                        <Typography
                            id="delete-modal-title"
                            variant="h6"
                            component="h2"
                        ></Typography>
                        <Typography id="delete-modal-description" sx={{ m: 2 }}>
                            {t("invited_user_list.confirm_message")}
                        </Typography>
                        <Button
                            sx={{ m: 2, p: 1 }}
                            onClick={() => deleteInvitation(deleteUserId)}
                        >
                            {t("invited_user_list.yes")}
                        </Button>
                        <Button sx={{ m: 2, p: 1 }} onClick={closeModal}>
                            {t("invited_user_list.no")}
                        </Button>
                    </Box>
                </Modal>
                {/* <Modal
                    open={inviteModalOpen}
                    onClose={closeModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="center-model">
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                        ></Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            {copyUrl}
                        </Typography>
                        <Button
                            sx={{ mt: 2 }}
                            onClick={() => navigator.clipboard.writeText(copyUrl)}
                        >
                            {t("invitation_page.Please_copy_this_link_to_register")}
                        </Button>
                    </Box>
                </Modal> */}
            </div>
            {/* Table data ends here */}
        </>
    );
};

export default Store;
