class StoreModel {
    private _storeId!: number;
    private _storeCode!: number;
    private _storeName!: string;
    private _userId!: number;
    private _email!: string;
    private _businessID!: number;
    private _countryID!: number;
    private _countryName!: string;
    private _stateID!: number;
    private _stateName!: string;
    private _cityID!: number;
    private _cityName!: string;
    private _address!: string;
    private _zipCode!: number;
    private _name!: string;
    private _statusText!: string;
    private _status!: number;
    private _invitationId!: number;
    private _companyName!: any;
    private _plateformFeeRate!: any;
    private _corporateName!: any;

    public static parseJson(itemStore: StoreModel) {
        const store: StoreModel = new StoreModel();

        store.storeId = itemStore.storeId;
        store.storeCode = itemStore.storeCode;
        store.storeName = itemStore.storeName;
        store.userId = itemStore.userId;
        store.email = itemStore.email;
        store.businessID = itemStore.businessID;
        store.countryID = itemStore.countryID;
        store.countryName = itemStore.countryName;
        store.stateID = itemStore.stateID;
        store.stateName = itemStore.stateName;
        store.cityID = itemStore.cityID;
        store.cityName = itemStore.cityName;
        store.address = itemStore.address;
        store.zipCode = itemStore.zipCode;
        store.name = itemStore.name;
        store.statusText = itemStore.statusText;
        store.status = itemStore.status;
        store.companyName = itemStore.companyName;
        store.corporateName = itemStore.corporateName;
        store.invitationId = itemStore.invitationId;
        store.plateformFeeRate = itemStore.plateformFeeRate;
        return store;
    }

    public get storeId() {
        return this._storeId;
    }
    public set storeId(storeId: number) {
        this._storeId = storeId;
    }
    public get storeCode() {
        return this._storeCode;
    }
    public set storeCode(storeCode: number) {
        this._storeCode = storeCode;
    }
    public get storeName() {
        return this._storeName;
    }
    public set storeName(storeName: string) {
        this._storeName = storeName;
    }
    public get userId() {
        return this._userId;
    }
    public set userId(userId: number) {
        this._userId = userId;
    }
    public get email() {
        return this._email;
    }
    public set email(email: string) {
        this._email = email;
    }
    public get businessID() {
        return this._businessID;
    }
    public set businessID(businessID: number) {
        this._businessID = businessID;
    }
    public get countryID() {
        return this._countryID;
    }
    public set countryID(countryID: number) {
        this._countryID = countryID;
    }
    public get countryName() {
        return this._countryName;
    }
    public set countryName(countryName: string) {
        this._countryName = countryName;
    }
    public get stateID() {
        return this._stateID;
    }
    public set stateID(stateID: number) {
        this._stateID = stateID;
    }
    public get stateName() {
        return this._stateName;
    }
    public set stateName(stateName: string) {
        this._stateName = stateName;
    }
    public get cityID() {
        return this._cityID;
    }
    public set cityID(cityID: number) {
        this._cityID = cityID;
    }
    public get cityName() {
        return this._cityName;
    }
    public set cityName(cityName: string) {
        this._cityName = cityName;
    }
    public get address() {
        return this._address;
    }
    public set address(address: string) {
        this._address = address;
    }
    public get zipCode() {
        return this._zipCode;
    }
    public set zipCode(zipCode: number) {
        this._zipCode = zipCode;
    }
    public get name() {
        return this._name;
    }
    public set name(name: string) {
        this._name = name;
    }
    public get statusText() {
        return this._statusText;
    }
    public set statusText(statusText: string) {
        this._statusText = statusText;
    }
    public get status() {
        return this._status;
    }
    public set status(status: number) {
        this._status = status;
    }
    public get companyName() {
        return this._companyName;
    }
    public set companyName(companyName: any) {
        this._companyName = companyName;
    }

    public get corporateName() {
        return this._corporateName;
    }
    public set corporateName(corporateName: any) {
        this._corporateName = corporateName;
    }
    
    public get invitationId() {
        return this._invitationId;
    }
    public set invitationId(invitationId: number) {
        this._invitationId = invitationId;
    }
    public get plateformFeeRate() {
        return this._plateformFeeRate;
    }
    public set plateformFeeRate(plateformFeeRate: number) {
        this._plateformFeeRate = plateformFeeRate;
    }
    
}

export default StoreModel;