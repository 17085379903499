import { API } from "../../../config";
import { LIST_LIMIT } from "../../../constants/constants";
import PaginationModel from "../../../models/Pagination/PaginationModel";
import StoreListPaginationModel from "../../../models/StoreList/storeListPaginationModel";
import StoreModel from "../../../models/StoreList/storeModel";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { serverAPIPath } from "../config/apiPath";

class StoreService {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async storeList(page_id: number, search?: string, limit= LIST_LIMIT.STORE_LIST_LIMIT, colName?:string,order?:string){
        const response = search ? 
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.STORELIST}?page=${page_id}&limit=${limit}&search=${search}`, this.payload, this.headers).getNew() :
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.STORELIST}?page=${page_id}&limit=${limit}&column=${colName}&order=${order}`, this.payload, this.headers).getNew();
        let storeListPaginationModel:StoreListPaginationModel = new StoreListPaginationModel();
        const storeList:StoreModel[] = []; 
        if(response?.data?.success == true)
        {            
            response?.data?.data?.data?.forEach((item: StoreModel) => {
                let store:StoreModel = new StoreModel();
                store = StoreModel.parseJson(item);
                storeList.push(store);
            });   
            let paginationData:PaginationModel = new PaginationModel();
            paginationData = PaginationModel.parseJson(response?.data?.data);

            storeListPaginationModel = StoreListPaginationModel.parseJson(storeList,paginationData);
            return storeListPaginationModel;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }

    async inviteUser(){
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.INVITE_USER}`, this.payload, this.headers).post();
        let isUserIvited;
        if(response?.data?.success==true) {
            isUserIvited = response?.data;
            return isUserIvited;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }

    async verifyInviteLink(code: any){
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.VERIFY_INVITE_LINK}${code}`, this.payload,this.headers).put();
        let isInviteLinkVerified: string;
        if(response?.data?.success==true) {
            isInviteLinkVerified = response?.data?.message;
            return isInviteLinkVerified;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }

    async deleteAdmin(itemId: number){
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.DELETE_INVITED_USER}`+itemId, this.payload, this.headers).delete();
        let responeMessage: string;

        if(response?.data?.success==true) {
            responeMessage = response?.data?.message;
            return responeMessage;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);

        }
    }

    async reInviteAdmin(){
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.REINVITE_USER}`, this.payload, this.headers).post();
        let isUserIvited;
        if(response?.data?.success==true) {
            isUserIvited = response?.data;
            return isUserIvited;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }
    async refreshAccessToken() {
        const response = await new AxiosUtils("").refreshAccessToken();
        let newToken;
        if (response?.data?.success) {
            newToken = response?.data?.data?.access_token;
            new Cookies("access-token", newToken).save(); // Save new access token in cookie
        } else if (response?.success === false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

}

export default StoreService;