import { snackbarActions } from "../redux/features/snackbar/snackbarSlice";

export const snackbarUtils = (dispatch:any, message: string, success: boolean, autoClose: boolean) => {
    dispatch(
        snackbarActions.SHOW_SNACKBAR({
            show: true,
            message,
            success,
            autoClose
        })
    );
};