import { API } from "../../../config";
import { OrderType } from "../../../interfaces/OrderInterface";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { serverAPIPath } from "../config/apiPath";


class OrderDetailService {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;
    

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async OrderDetail(orderId:any,code:any) {
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.ORDER_DETAIL_LIST}?storeCode=${code}&orderId=${orderId}`, this.payload, this.headers).get();
        if (response?.data?.success) {
            const OmsConfirmData: OrderType = response.data.data;
            return OmsConfirmData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

}
export default OrderDetailService;