import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";
import IconExpandLess from "@mui/icons-material/ExpandLess";
import IconExpandMore from "@mui/icons-material/ExpandMore";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { ROUTES } from "../../../constants/routes";
import { permissionCategory } from "../../../constants/permissionCategory";
import { ProtectedMenu } from "../../../middleware/ProtectedMenu";


export default function SideNavigation() {
    const [isOpen, setIsOpen] = useState(false);
    // const [openUserList, setOpenUserList] = React.useState(false);
    const [openPayment, setOpenPayment] = React.useState(false);
    const [openCategory, setOpenCategory] = React.useState(false);
    const { t } = useTranslation();
    const toggleDrawer =
        (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === "keydown" &&
                ((event as React.KeyboardEvent).key === "Tab" ||
                    (event as React.KeyboardEvent).key === "Shift")
            ) {
                return;
            }
            setIsOpen(open);
            // setOpenUserList(false);
            setOpenPayment(false);
            setOpenCategory(false);
        };

    // function handleClickUserList() {
    //     setOpenUserList(!openUserList);
    //     // setOpenPayment(!openPayment);
    // }
    function handleClickPayment() {

        setOpenPayment(!openPayment);
    }
    function handleClickCategory() {
        setOpenCategory(!openCategory);
    }

    function handleClickClose() {
        setIsOpen(false);
        // setOpenUserList(false);
        setOpenPayment(false);
        setOpenCategory(false);
    }

    const menuData = [
        {
            category: permissionCategory.STORE_MANAGEMENT,
            element: (
                <Link to={ROUTES.INITIAL_PAGE} onClick={() => setIsOpen(false)}>
                    <ListItem component="div" className="side-navbar-list-items">
                        <ListItemText primary={t("side_menu.store_management")} />
                    </ListItem>
                </Link>
            )
        },
        {
            category: permissionCategory.PAYMENT_MANAGEMENT,
            element: (
                <>
                    <ListItem onClick={handleClickPayment} className="side-nav-bar-list">
                        <ListItemText primary={t("side_menu.sales_receipt_management")} />
                        {openPayment ? <IconExpandLess /> : <IconExpandMore />}
                    </ListItem>

                    <>
                        <Collapse in={openPayment} timeout="auto" unmountOnExit>
                            <List component="div" className="side-navbar-list-items">
                                <Link to={ROUTES.SALE_LIST} onClick={() => setIsOpen(false)}>
                                    <ListItem>
                                        <ListItemText primary={t("side_menu.sales_list")} />
                                    </ListItem>
                                </Link>
                                <Link to={ROUTES.PAYMENT_HISTORY} onClick={() => setIsOpen(false)}>
                                    <ListItem>
                                        <ListItemText primary={t("side_menu.sales_history")} />
                                    </ListItem>
                                </Link>
                            </List>
                        </Collapse>
                    </>
                </>
            )
        },
        {
            category: permissionCategory.ADMIN_USER_MANAGEMENT,
            element: (
                <Link to={ROUTES.ADMIN_USERS} onClick={() => setIsOpen(false)}>
                    <ListItem component="div" className="side-navbar-list-items">
                        <ListItemText primary={t("side_menu.admin_users_list_menu")} />
                    </ListItem>
                </Link>
            )
        },
        {
            category: permissionCategory.SUPER_ADMIN,
            element: (
                <Link to={ROUTES.ROLE_MANAGEMENT_SYSTEM} onClick={() => setIsOpen(false)}>
                    <ListItem component="div" className="side-navbar-list-items">
                        <ListItemText primary={t("side_menu.rms")} />
                    </ListItem>
                </Link>
            )
        },
        {
            category: permissionCategory.SUPER_ADMIN,
            element: (
                <Link to={ROUTES.CATEGORY_LIST} onClick={() => setIsOpen(false)}>
                    <ListItem className="side-nav-bar-list">
                        <ListItemText primary={t("side_menu.category_management")} />
                    </ListItem>
                </Link>
            )
        },
        {
            category: permissionCategory.SUPER_ADMIN,
            element: (
                <Link to={ROUTES.STUCKED_TRANSACTION} onClick={() => setIsOpen(false)}>
                    <ListItem component="div" className="side-navbar-list-items">
                        <ListItemText primary={t("side_menu.stucked_transaction")} />
                    </ListItem>
                </Link>
            )
        },
        {
            category: permissionCategory.SUPER_ADMIN,
            element: (
                <Link to={ROUTES.ENCRYPTION_DECRYPTION} onClick={() => setIsOpen(false)}>
                    <ListItem component="div" className="side-navbar-list-items">
                        <ListItemText primary={t("encryption_decryption.encryption_decryption_tools")} />
                    </ListItem>
                </Link> 
            )
        },
        {
            category: permissionCategory.SUPER_ADMIN,
            element: (
                <Link to={ROUTES.SYSTEM_UPDATE} onClick={() => setIsOpen(false)}>
                    <ListItem className="side-nav-bar-list">
                        <ListItemText primary={t("side_menu.notice_management")} />
                    </ListItem>
                </Link>
            )
        },
        {
            category: permissionCategory.SUPER_ADMIN,
            element: (
                <Link to={ROUTES.EDIT_MAINTENANCE} onClick={() => setIsOpen(false)}>
                    <ListItem className="side-nav-bar-list">
                        <ListItemText primary={t("side_menu.maintenance_management")} />
                    </ListItem>
                </Link>
            )
        },
        {
            category: permissionCategory.SUPER_ADMIN,
            element: (
                <Link to={ROUTES.ACTIVITY_LOG} onClick={() => setIsOpen(false)}>
                    <ListItem className="side-nav-bar-list">
                        <ListItemText primary={t("side_menu.activity_log")} />
                    </ListItem>
                </Link>
            )
        },
    ];
         
    const list = (
        <>
            <ListItem
                onClick={handleClickClose}
                className="side-nav-bar-close-section"
            >
                <CloseRoundedIcon />
            </ListItem>

            <List component="nav" disablePadding>
                {
                    menuData?.map((item, menuIndex) => {
                        return (
                            <ProtectedMenu key={menuIndex} category={item?.category}>{item?.element}</ProtectedMenu>
                        );
                    })
                }
            </List>
        </>
    );

    return (
        <>
            <React.Fragment>
                <MenuIcon sx={{ p: 2 }} onClick={() => setIsOpen(true)} />
                <Drawer
                    open={isOpen}
                    onClose={toggleDrawer(false)}
                    className="header-side-navbar"
                >
                    {list}
                </Drawer>
            </React.Fragment>
        </>
    );
}
