export const mockAPIPath = {
    SALES_HISTORY: "",
    DUMMY_URL: "",
    PAYMENT_HISTORY_LIST: "./data/paymentHistory.json",
    PAYMENT_HISTORY_SELLER_NAME: "./data/sellerName.json",
    SALES_STATEMENT: "./data/SalesStatement.json"
};

export const serverAPIPath = {
    SALES_HISTORY: "/oms/sales/history",
    DUMMY_URL: "/oms/sales/calculation",
    PAYMENT_HISTORY_LIST: "/oms/sales/history",
    PAYMENT_HISTORY_SELLER_NAME: "/user/sellers/name",
    SALES_STATEMENT: "/oms/sales/statement"
};
