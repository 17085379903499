export const mockAPIPath = {
    PAYMENT_TABLE:"./data/paymentTableData.json",
    PAYEE_TABLE:"./data/payeeTableData.json",
    ORDER_TABLE_HEADER:"./data/orderTableHeader.json",
    PAYMENT_TABLE_HEADER:"./data/paymentTableHeader.json"
};

export const serverAPIPath = {
    FILE_UPLOAD:"/file-upload",
    PAYMENT_TABLE:"/tool/encrypt-decrypt/payments",
    PAYEE_TABLE:"/tool/encrypt-decrypt/orders",
    ORDER_TABLE_HEADER:"./data/orderTableHeader.json",
    PAYMENT_TABLE_HEADER:"./data/paymentTableHeader.json"
    
};