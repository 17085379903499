import { Button, CircularProgress } from "@mui/material";
import React, { useEffect,  useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { ROUTES } from "../../constants/routes";
import CONSTANTS, { ROLE_MANAGEMENT_SYSTEM } from "../../constants/constants";
import { permissionCategory } from "../../constants/permissionCategory";

const DownloadButton = (props:any) =>{
    
    const {loading, functionToExecute, route, className, btnName, disableCondition, sx}=props;

    const userType = useAppSelector((state) => state.common.loggedInUserType);

    const [isPermissionallowed, setIsPermissionAllowed] = useState<boolean>(true);
    
    const permission_list = useAppSelector(
        (state) => state.permission.permissionDetails.modules?.admin
    );
    /**
 * @description -   setting isPermissionAllowed state to true if current route has download permission
 */
    useEffect(() => {
        const checkPermission = () => {
          
            if (route == ROUTES.PAYMENT_HISTORY || route == ROUTES.SALE_LIST) {
                const productManagementPermission = permission_list?.find((item:any) => item.key == permissionCategory.PAYMENT_MANAGEMENT);
                setIsPermissionAllowed(productManagementPermission?.permissions?.download == ROLE_MANAGEMENT_SYSTEM.PERMISSION_NOTALLOWED ? false :true);
            } else {
                setIsPermissionAllowed(true);
            }
        };
    
        if(userType == CONSTANTS.USER_TYPE_ADMIN){
            checkPermission();
        }
    }, [route,permission_list]);



    return(

        <>
            <Button
                className={className}
                variant="contained"
                disabled={!isPermissionallowed ? !isPermissionallowed : disableCondition}
                sx={sx ? sx : {textTransform: "capitalize"}}
                onClick={functionToExecute}
            >
                {btnName}
                {loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                        }}
                    />
                )}
            </Button> 
        </>
        

    );

};
export default DownloadButton;