const ENC_DEC_CONFIG = {
    PAYMENT_TABLE_COLUMN : 20,
    ORDER_TABLE_COLUMN: 60,
    MAX_ROW_COUNT:10001,
    FILE_SIZE:10000000,
    SELLER_NAME:"sellerName",
    SELLER_CODE:"sellerCode",
    PAYEE_NAME:"payeeName",
    PAYEE_CODE:"payeeCode"

};

export default ENC_DEC_CONFIG;