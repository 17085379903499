class CronJobModel {
    private _settlementDate!: number;
    private _paymentCompletionDate!: number;
    private _pointRedemptionRate!: string;
    private _salesCommissionRate!: string;
    

    public static parseJson(cronJob: CronJobModel) {
        const cronJobLog: CronJobModel = new CronJobModel();
        cronJobLog.settlementDate = cronJob.settlementDate;
        cronJobLog._paymentCompletionDate = cronJob.paymentCompletionDate;
        cronJobLog.pointRedemptionRate = cronJob.pointRedemptionRate;
        cronJobLog.salesCommissionRate = cronJob.salesCommissionRate;
        return cronJobLog;
    }

    public get settlementDate() {
        return this._settlementDate;
    }
    public set settlementDate(settlementDate: number) {
        this._settlementDate = settlementDate;
    }
    public get paymentCompletionDate(){
        return this._paymentCompletionDate;
    }
    public set paymentCompletionDate(paymentCompletionDate:number){
        this._paymentCompletionDate = paymentCompletionDate;
    }
    public get pointRedemptionRate() {
        return this._pointRedemptionRate;
    }
    public set pointRedemptionRate(pointRedemptionRate: string) {
        this._pointRedemptionRate = pointRedemptionRate;
    }
    
    public get salesCommissionRate() {
        return this._salesCommissionRate;
    }
    public set salesCommissionRate(salesCommissionRate: string) {
        this._salesCommissionRate = salesCommissionRate;
    }
    
}

export default CronJobModel;