import { API } from "../../../config";
import UserModel from "../../../models/user/userModel";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";


class AuthService {

    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async register(){
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.REGISTER}`, this.payload).post();
        let userDetails: UserModel = new UserModel();
        if(response?.data?.success==true) {
            userDetails = UserModel.parseJson(response?.data?.data);
            return userDetails;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }

    async login() {
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.LOGIN}`, this.payload).post();
        let userDetails: UserModel = new UserModel();
        if(response?.data?.success == true)
        {            
            userDetails = UserModel.parseJson(response?.data?.data);
            return userDetails;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
           
    }

    async logout() {
        await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.LOGOUT}`, {}, this.headers).post();
        new Cookies("access-token").delete();
    }

    async verifyEmail(){
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.VERIFY_EMAIL}`, this.payload).post();
        let isEmailVerified: string;
        if(response?.data?.success==true) {
            isEmailVerified = response?.data?.message;
            return isEmailVerified;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }
    
    async getRolePermissions(roleId:any = 0) {
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ?
            await new AxiosUtils(mockAPIPath.ROLES_BASED_PERMISSION).get() : 
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.ROLES_BASED_PERMISSION}/${roleId}`, {}, this.headers).get();
        let adminPermissions: object;
        if(response?.data?.success==true) {
            adminPermissions = response?.data?.data;
            return adminPermissions;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        } 
    }
}

export default AuthService;