
import React, {useState, useEffect, useRef} from "react";
import { Box, Container, Grid, Typography , FormControlLabel,  FormGroup,  Switch, Button, TextareaAutosize  } from "@mui/material";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useTranslation } from "react-i18next";
import UnderMaintenanceService from "./services/UnderMaintenanceService";
import SimpleReactValidator from "simple-react-validator";
import { commonActions } from "../../redux/features/common/commonSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Skeleton from "../common/Skeleton";
import dayjs from "dayjs";
import CONSTANTS from "../../constants/constants";
import "dayjs/locale/ja"; // Import Japanese locale for dayjs
import ModalUtility from "../../utils/modalUtility";


const EditMaintenance = (props: any) => {

    const { showSnackbar } = props;
    const defaultText = "※メンテナンス期間は延長される可能性がございます";

    const [t] = useTranslation();
    const [fromParam,setFromParam] = useState<any>("");
    const [toParam,setToParam] = useState<any>("");
    const [valueFrom, setValueFrom] = useState<any>(null);
    const [valueTo, setValueTo] = useState<any>(null);
    const [skeleton, setSkeleton] = useState(true);
    const [maintenanceSwitch, setMaintenanceSwitch] = useState<boolean>(false);
    const [inputvalue, setInputvalue] = useState<string>("");
    // const [maintenanceData, setMaintenanceData] = useState<any>("");
    const [fromDateError, setFromDateError] = useState<boolean>(false);
    const [toDateError, setToDateError] = useState<boolean>(false);
    const [invalidDate,setInvalidDate]=useState<boolean>(false);
    const [, forceUpdate] = useState(0);

    useEffect(() => {
        forceUpdate(1);
        document.querySelector(".from-date-seller-picker input")?.setAttribute("disabled","true");
        document.querySelector(".to-date-seller-picker input")?.setAttribute("disabled","true");
    });

    useEffect(()=>{
        GetMaintenancePage();
        document.querySelector(".from-date-seller-picker input")?.setAttribute("disabled","true");
        document.querySelector(".to-date-seller-picker input")?.setAttribute("disabled","true");
    },[]);

    useEffect(()=>{
        maintenanceSwitch == false ? setInputvalue(defaultText) : null;
    }, [maintenanceSwitch]);
    
    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {             
                required: t("required.this_field_cannot_be_empty"),
                max: t("under_maintenance.max_2000_char"),
            },
        })
    );
    const validatorForDate = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {             
                required: t("required.this_field_cannot_be_empty"),
            },
        })
    );


    const dispatch = useAppDispatch();

    const handleChangeFrom = (value:any) =>{
        const {$D, $M, $y, $H, $m} = value;
        let month = $M+1;
        let dateto = $D;
        let hour = $H;
        let min = $m;
        month = month < 10 ? "0" + month : month;
        dateto = dateto < 10 ? "0" + dateto : dateto;
        hour = hour < 10 ? "0" + hour : hour;
        min = min < 10 ? "0" + min : min;
        setFromParam(`${$y}/${month}/${dateto} ${hour}:${min}`);
        setValueFrom(value);
    };

    
    const handleChangeTo = (value:any) =>{
        const {$D, $M, $y, $H, $m} = value;
        let month = $M+1;
        let dateto = $D;
        let hour = $H;
        let min = $m;
        month = month < 10 ? "0" + month : month;
        dateto = dateto < 10 ? "0" + dateto : dateto;
        hour = hour < 10 ? "0" + hour : hour;
        min = min < 10 ? "0" + min : min;
        setToParam(`${$y}/${month}/${dateto} ${hour}:${min}`);
        setValueTo(value);
    };

    const maintenanceHandleChange = (event: React.ChangeEvent<HTMLInputElement>) =>{
        setMaintenanceSwitch(event.target.checked);   
    };
   

    const handleTextArea = (e:any) =>{
        maintenanceSwitch? setInputvalue(e.target.value):setInputvalue(defaultText);
    };

    const PostMaintenancePage = () => {
        const payload: any = {
            "maintenanceMode" : maintenanceSwitch.toString(),
            "maintenanceModeFrom": fromParam,
            "maintenanceModeTo": toParam,
            "maintenanceModeMessage":inputvalue,
            "systemUpdateNotification": localStorage.getItem("systemUpdateNotification"),
            "systemUpdateNotificationVisibility": localStorage.getItem("systemUpdateNotificationVisibility"),
        };

        const isHitDummyURL = false;
        new UnderMaintenanceService(payload, isHitDummyURL)
            .UnderMaintenancePostService()
            .then((res) => {
                showSnackbar(res, true);
                location.reload();
            })
            .catch((err) => {
                showSnackbar(err?.message, false);
            });
    };

    const GetMaintenancePage = () => {
        const isHitDummyURL = false;
        new UnderMaintenanceService({}, isHitDummyURL)
            .UnderMaintenanceGetService()
            .then((res:any)=>{
                // setMaintenanceData(res);
                setMaintenanceSwitch(res?.maintenanceMode=="true");
                setInputvalue(res?.maintenanceModeMessage);
                dispatch(commonActions.IS_MAINTENANACE(res.maintenanceMode));
                setFromParam(res.maintenanceModeFrom);
                setToParam(res.maintenanceModeTo);
                setValueFrom(res.maintenanceModeFrom==""?null:dayjs(res.maintenanceModeFrom));
                setValueTo(res.maintenanceModeTo==""?null:dayjs(res.maintenanceModeTo));
                setSkeleton(false);
            })
            .catch((err)=>{
                showSnackbar(err?.message, false);
                setSkeleton(false);
            });
    };

    const handleSubmit = () =>{  
        if(valueFrom <= valueTo || valueTo == null){
            if(toDateError!=true && fromDateError!=true ){
                if (validator.current.allValid()) {
                    if (validatorForDate.current.allValid()|| valueFrom==null) {
                        PostMaintenancePage();  
                    }else {
                        validatorForDate.current.showMessages();
                    }
                }
                else {
                    validator.current.showMessages();
                    if (!validatorForDate.current.allValid() && valueFrom!==null) {
                        validatorForDate.current.showMessages();
                    }
                }
            }
        }else{
            setInvalidDate(true);    
        }
    };
    // const shouldDisableTimeTo = (time:any) => {
    //     if (valueFrom) {
    //         return time <= valueFrom;
    //     }
    //     return false;
    // };
    // const shouldDisableTimeFrom = (time:any) => {
    //     if (valueTo) {
    //         return time >= valueTo;
    //     }
    //     return false;
    // };

    const userType = useAppSelector((state) => state.common.loggedInUserType);
    const resetFilter = () =>{
        setFromParam("");
        setToParam("");
        setValueFrom(null);
        setValueTo(null);
    };



    return (
        <>
            <Container>
                {
                    skeleton ? (<Skeleton  varient="rectangular"   width={0}  height={60}  count="10"  my={0} /> ) :(
                        <Grid container className="maintanance_page">
                            <Grid item xs={12} xl={7} lg={7} md={7} sm={12}>
                                <Grid className="under_maintanance"> 
                                    <Box sx={{mt:2}}>
                                        {/* <Typography component="h3">{t("under_maintenance.server_maintenance")}</Typography> */}
                                        <FormGroup className="server_maintinence_onff"> 
                                
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={maintenanceSwitch}
                                                        onChange={(e)=>maintenanceHandleChange(e)}
                                                        inputProps={{ "aria-label": "controlled" }}
                                                    />
                                                }
                                                label={t("under_maintenance.server_maintenance_on_off")}/>
                                        </FormGroup>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} xl={5} lg={5} md={4} sm={4}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja" dateFormats={{ monthAndYear: "YYYY年MM月" }}>
                                              
                                                    <DemoContainer
                                                        components={["DateTimePicker"]}>
                                                        <DemoItem>
                                                            <Typography>{t("under_maintenance.from")}</Typography>
                                                            <DateTimePicker
                                                                className="from-date-seller-picker input"
                                                                format="YYYY/MM/DD HH:mm"
                                                                // label = {maintenanceData.maintenanceModeFrom}
                                                                value={valueFrom}
                                                                // disablePast
                                                                onChange = {handleChangeFrom}
                                                                maxDate={valueTo}
                                                                // shouldDisableTime={shouldDisableTimeFrom}
                                                                views={["year", "month", "day", "hours", "minutes"]}
                                                                onError={(error) => setFromDateError(!!error)}

                                                                // defaultValue={dayjs(maintenanceData?.maintenanceModeFrom)}
                                                            />
                                                            {/* {(validator.current.message("from-date-seller-picker", valueFrom, [
                                                                "required",
                                                            ]))} */}
                                                        </DemoItem>
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={12} xl={5} lg={5} md={4} sm={4}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja" dateFormats={{ monthAndYear: "YYYY年MM月" }}>
                                                    <DemoContainer
                                                        components={["DateTimePicker"]}>
                                                        <DemoItem>
                                                            <Typography>{t("under_maintenance.to")}</Typography>
                                                            <DateTimePicker
                                                                className="to-date-seller-picker input"
                                                                format="YYYY/MM/DD HH:mm" 
                                                                // label = {maintenanceData.maintenanceModeTo}
                                                                value={valueTo}
                                                                onChange={handleChangeTo}
                                                                minDate={valueFrom}
                                                                // shouldDisableTime={shouldDisableTimeTo}
                                                                views={["year", "month", "day", "hours", "minutes"]}
                                                                disabled={!valueFrom || valueFrom ==null}
                                                                onError={(error) => setToDateError(!!error)}
                                                                // defaultValue={dayjs(maintenanceData?.maintenanceModeTo)}
                                                            />
                                                            {(validatorForDate.current.message("to-date-seller-picker", valueTo, [
                                                                "required",
                                                            ]))}
                                                        </DemoItem>
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                               
                                            </Grid>
                                            <Grid item xs={6} sm={4} md={3} lg={2} xl={2} className="edit_mainatinance_clear_btn">
                                                <Button
                                                    // variant="contained"                           
                                                    sx={{textTransform: "capitalize"}}
                                                    onClick={resetFilter}
                                                    className="edit_mainatinance_clear_btn"
                                                >
                                                    {t("pr.reset")}
                                                </Button>
                                            </Grid> 
                                        </Grid>

                                        <Box sx={{my:2}}>
                                            {/* <Typography sx={{my:1}}>{t("under_maintenance.last_maintenance_period")}</Typography> */}
                                            {/* <Typography sx={{mb:2}}>{maintenanceData?.maintenanceModeFrom} - {maintenanceData?.maintenanceModeTo}</Typography> */}
                                            <Typography sx={{my:1}}>{t("under_maintenance.maintenance_message")}</Typography>
                                            <TextareaAutosize
                                                className="textarea"
                                                id="emptyValue"
                                                value={inputvalue}
                                                onChange={(e)=>handleTextArea(e)}
                                                minRows={10}/>
                                            {validator.current.message("textarea", inputvalue, [
                                                "required",
                                                { max: 200 },
                                            ])}


                                        </Box>

                                        {
                                            userType == CONSTANTS.USER_TYPE_SUPER_ADMIN ? <Button variant="contained" fullWidth type="submit" sx={{height:"55px", width:"83%"}} onClick={handleSubmit}>{t("under_maintenance.submit")}</Button> : <Button variant="contained" fullWidth type="submit" sx={{height:"55px", width:"83%"}}disabled>{t("under_maintenance.submit")}</Button>
                                        }
                                        
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} xl={5} lg={5} md={5} sm={5}>
                                <img className="under_img" alt="cainz"src="./assets/images/undermaintenance.png"/>
                            </Grid>
                        </Grid>
                    )}
            </Container>
            { invalidDate? 
                <ModalUtility msg={t("under_maintenance.date_validation")} modalOpen={invalidDate} setModalOpen={setInvalidDate}/>
                :""
            }
        </>
    );
};

export default EditMaintenance;

