import {createSlice, PayloadAction} from "@reduxjs/toolkit";

type InitialState = {
    loaderInfo:{
        showLoader?: boolean,
        showMessage?: boolean,
    }

};

const initialState: InitialState = {
    loaderInfo:{
        showLoader: false,
        showMessage: true,
    }
};

const loaderSlice = createSlice({
    name: "loaderss",
    initialState,
    reducers: {
        SHOW_LOADER: (state, action: PayloadAction<boolean>) => {
            state.loaderInfo.showLoader = action.payload;
        },
        HIDE_LOADER: (state, action: PayloadAction<boolean>) => {
            state.loaderInfo.showLoader = action.payload;
        }
    }
});

export default loaderSlice.reducer;
export const loaderActions = loaderSlice.actions;