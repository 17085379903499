import React, { useState, useRef, useEffect } from "react";
import {
    Container,
    Grid,
    TextField,
    Button,
    IconButton,
    InputAdornment,
    Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { imagePath } from "../../../utils/assetUtils";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import CONSTANTS from "../../../constants/constants";
import AuthService from "../service/AuthService";
import SimpleReactValidator from "simple-react-validator";
import { ROUTES } from "../../../constants/routes";

const Register = (props: any) => {
    const { showSnackbar,handleLoader } = props;
    const navigate = useNavigate();
    const { login } = props;

    const [t] = useTranslation();
    const [passwordType, setPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    // const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    //     null
    // );
    const [searchParams, SetSearchParam] = useSearchParams();
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    // const [alertMessage, setAlertMessage] = useState("");
    // const [successAlert, setSuccessAlert] = useState(false);
    const error = {
        nameError: "",
        passwordError: "",
        confirmPasswordError: "",
    };
    // const [userDetails, setUserDetails] = useState({});
    // const [openSnackBar, setOpenSnackBar] = useState(false);
    useEffect(() => {
        !searchParams.get("code") ? navigate(ROUTES.INITIAL_PAGE) : null;
    });
    const handleChange = (e: any) => {
        const { value, name } = e.target;
        name === "name"
            ? setName(value)
            : name === "password"
                ? setPassword(value)
                : setConfirmPassword(value);
        validator.current.hideMessageFor(name);
    };

    const handleOnBlur = (e: any) => {
        const { name } = e.target;

        validator.current.showMessageFor(name);
    };

    // validation
    const [, forceUpdate] = useState(0);

    const passwordRestrictionWord = CONSTANTS.RESTRICTED_KEYWORD;
    const isMatchedPasswordRestrictionWord = (password: string) => {
        const isMatched = passwordRestrictionWord.find((item) => {
            if (password.toLowerCase().includes(item.toLowerCase())) {
                return true;
            }
        });
        if (isMatched) {
            return isMatched;
        }
        return false;
    };

    const validator = useRef(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate },
            className: "text-danger",
            messages: {
                in: t("register.password_does_not_match"),
                max: t("register.max_200_char"),
                regex: t("register.please_enter_valid_password"),
                required: t("required.this_field_cannot_be_empty"),
            },
            validators: {
                cainz: {
                    message: t("required.the_password_can_not_contain"),
                    rule: (val: string) => {
                        return !isMatchedPasswordRestrictionWord(val);
                    },
                },
            },
        })
    );

    useEffect(() => {
        forceUpdate(1);
    });

    const handleSubmit = () => {
        if (validator.current.allValid()) {
            handleLoader(true);
            const payload = {
                full_name: name,
                password: password,
                confirmPassword: confirmPassword,
                code: searchParams.get("code"),
                userType: searchParams.get("type")
            };
            const isHitDummyURL = false;
            new AuthService(payload, isHitDummyURL)
                .register()
                .then((res) => {
                    handleLoader(false);
                    const { accessToken, email, name , roleId  } = res;
                    login(accessToken, email, name , roleId);
                    // setUserDetails(res);
                    navigate(ROUTES.INITIAL_PAGE);
                })
                .catch((err) => {
                    handleLoader(false);
                    showSnackbar(err?.message, false);
                });
        } else {
            validator.current.showMessages();
        }
    };

    // const handleCloseSnackBar = () => {
    //     setOpenSnackBar(false);
    // };


    // const passSuggestion = (e: any) => {
    //     setAnchorEl(e.currentTarget);
    // };

    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    const handleClickShowPassword = () => {
        passwordType=="password" ? setPasswordType("text") : setPasswordType("password"); 
    };

    // const handleMouseDownPassword = () => {
    //     setPasswordType("text");
    // };

    const handleClickShowConfirmPassword = () => {
        confirmPasswordType=="password" ? setConfirmPasswordType("text") : setConfirmPasswordType("password");  
    };

    // const handleMouseDownConfirmPassword = () => {
    //     setConfirmPasswordType("text");
    // };
    // const open = Boolean(anchorEl);
    return (
        <>
            <div className="main">
                {/* Register page starts here */}
                <Container>
                    <Grid container>
                        {/* offset grid with column*/}
                        <Grid item xs={12} xl={4} lg={4} md={2} sm={12}></Grid>
                        {/* main grid column! */}
                        
                        <Grid item xs={12} xl={4} lg={4} md={8} sm={12}>
                            <img
                                className="logo-img"
                                alt="cainz"
                                src={imagePath("logo-large.png")}
                            />
                           
                            <Grid sx={{ mb: 2 }}>
                                <TextField
                                    type={name}
                                    fullWidth
                                    value={name}
                                    name="name"
                                    label={t("register.full_name")}
                                    variant="outlined"
                                    onChange={(e) => handleChange(e)}
                                    error={error.nameError ? true : false}
                                    helperText={error.nameError}
                                    onBlur={(e) => handleOnBlur(e)}
                                />
                                {validator.current.message("name", name, [
                                    "required",
                                    { max: 200 },
                                ])}
                            </Grid>

                            <Grid sx={{ mb: 2 }}>
                                <TextField
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    // onTouchStart={handleMouseDownConfirmPassword}
                                                    // onTouchEnd={handleClickShowConfirmPassword}
                                                    onClick={handleClickShowPassword}
                                                    // onMouseDown={handleMouseDownConfirmPassword}
                                                >
                                                    { passwordType=="password" ?
                                                        <VisibilityIcon /> :
                                                        <VisibilityOffIcon />
                                                    }
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    type={passwordType}
                                    fullWidth
                                    name="password"
                                    label={t("register.password")}
                                    variant="outlined"
                                    value={password}
                                    onChange={(e) => handleChange(e)}
                                    error={error.passwordError ? true : false}
                                    helperText={error.passwordError}
                                    onBlur={(e) => handleOnBlur(e)}
                                />
                                {validator.current.message("password", password, [
                                    "required",
                                    CONSTANTS.RESTRICTED_KEYWORD.toString(),
                                    { regex: CONSTANTS.PASSWORD_REGEX },
                                ])}
                            </Grid>
                            <Grid sx={{ mb: 2 }}>
                                <TextField
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleClickShowConfirmPassword}
                                                >
                                                    { confirmPasswordType=="password" ? 
                                                        <VisibilityIcon /> : 
                                                        <VisibilityOffIcon />
                                                    }
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    type={confirmPasswordType}
                                    fullWidth
                                    name="confirmPassword"
                                    label={t("register.confirm_password")}
                                    variant="outlined"
                                    value={confirmPassword}
                                    onChange={(e) => handleChange(e)}
                                    error={error.confirmPasswordError ? true : false}
                                    helperText={error.confirmPasswordError}
                                    onBlur={(e) => handleOnBlur(e)}
                                />
                                {validator.current.message(
                                    "confirmPassword",
                                    confirmPassword,
                                    [
                                        "required",
                                        CONSTANTS.RESTRICTED_KEYWORD.toString(),
                                        { in: password },
                                        { regex: CONSTANTS.PASSWORD_REGEX },
                                    ]
                                )}
                            </Grid>

                            <Typography sx={{my:1, width: "auto", color:"#201d1d94"}}>
                                {t("create_password.password_suggestion")}
                            </Typography>

                            <Grid sx={{ mb: 2 }}>
                                <Button fullWidth onClick={handleSubmit}>
                                    {t("register.register")}
                                </Button>
                            </Grid>
                        </Grid>
                           
                        
                        {/* offset grid with column*/}
                        <Grid item xs={12} xl={4} lg={4} md={2} sm={12}></Grid>
                    </Grid>
                </Container>
              
                {/* Register page ends here */}
            </div>
        </>
    );
};

export default Register;
