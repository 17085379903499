import { API } from "../../../../config";
import AxiosUtils from "../../../../utils/AxiosUtils";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";
import Cookies from "../../../../utils/Cookies";
import UserPagination from "../../../../models/user/usersPagination";
import UserModel from "../../../../models/user/userModel";
import PaginationModel from "../../../../models/Pagination/PaginationModel";
import { LIST_LIMIT } from "../../../../constants/constants";
import { AdminUserRoleWithPaginationType } from "../../../../interfaces/AdminUserRoleInterface";

class AdminUserListService {

    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async adminUserList(page_id: number, search?: string, limit= LIST_LIMIT.ADMIN_LIST_LIMIT){
        const response = search ? 
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.ADMINUSERS}?page=${page_id}&limit=${limit}&search=${search}`, this.payload, this.headers).getNew() :
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.ADMINUSERS}?page=${page_id}&limit=${limit}`, this.payload, this.headers).getNew();
        let adminUserListPaginationModel:UserPagination = new UserPagination();
        const adminUserList:UserModel[] = []; 
        if(response?.data?.success == true)
        {  
            response?.data?.data?.data?.forEach((item: UserModel) => {
                let adminUser:UserModel = new UserModel();
                adminUser = UserModel.parseJson(item);
                adminUserList.push(adminUser);
            });
            
            
            let paginationData:PaginationModel = new PaginationModel();
            paginationData = PaginationModel.parseJson(response?.data?.data);

            adminUserListPaginationModel = UserPagination.parseJson(adminUserList,paginationData);
            return adminUserListPaginationModel;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }

    
    // async enableDisableUser(user_id: string){
    //     const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.ENABLE_DISABLE_USER}`+user_id, this.payload, this.headers).put();
    //     let isUserStatusUpdated: string;
    //     if(response?.data?.success==true) {
    //         isUserStatusUpdated = response?.data?.message;
    //         return isUserStatusUpdated;
    //     }
    //     else if(response?.success == false)
    //     {   
    //         throw new Error(response?.message);        
    //     }else{
    //         throw new Error(response);
    //     }
    // }


    async deleteAdmin(itemId: number){
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.DELETE_INVITED_USER}`+itemId, this.payload, this.headers).delete();
        let responeMessage: string;

        if(response?.data?.success==true) {
            responeMessage = response?.data?.message;
            return responeMessage;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);

        }
    }

    async deleteAdminUser(itemId: number){
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.DELETE_ADMIN}`+itemId, this.payload, this.headers).delete();
        let responeMessage: string;

        if(response?.data?.success==true) {
            responeMessage = response?.data?.message;
            return responeMessage;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);

        }
    }

    async adminUserDetails(userID: number) {
        const response = this.isHitDummyURL && API.HIT_DUMMY_URL ? 
            await new AxiosUtils(`${API.LOCAL_BASE_URL}${mockAPIPath.USER_DETAILS}`).getNew() : 
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.USER_DETAILS}${userID}`, this.payload, this.headers).getNew();
        let userDetails: UserModel = new UserModel();
        if(response?.data?.success){
            userDetails = UserModel.parseJson(response?.data?.data);
            return userDetails;
        }
        else if(!response?.data?.success)
        {   
            throw new Error(response?.data?.message);        
        }else{
            throw new Error(response);

        }
    }

    async reInviteAdmin(){
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.REINVITE_USER}`, this.payload, this.headers).post();
        let isUserInvited;
        if(response?.data?.success==true) {
            isUserInvited = response?.data;
            return isUserInvited;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }

    async updateAdminEmail(){
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.UPDATE_EMAIL}`, this.payload, this.headers).put();
        let isUserEmailUpdated;
        if(response?.data?.success == true) {
            isUserEmailUpdated = response?.data;
            return isUserEmailUpdated;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }

    async updateAdminName(){
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.UPDATE_NAME}`, this.payload, this.headers).put();
        let isUserEmailUpdated;
        if(response?.data?.success == true) {
            isUserEmailUpdated = response?.data;
            return isUserEmailUpdated;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }

    async availableEmail() {
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.EMAIL_VERIFY}`, this.payload, this.headers).post();
        let isUserEmailAvailable;
        if(response?.data?.success == true) {
            isUserEmailAvailable = response?.data;
            return isUserEmailAvailable;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }

    async adminUserRoleList(
        page_id: number,
        limit = LIST_LIMIT.STORE_LIST_LIMIT,
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.ADMIN_USER_ROLE}`,
                this.payload
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.ADMIN_USER_ROLE}?page=${page_id}&limit=${limit}`,
                this.payload,
                this.headers
            ).get();
        if (response?.data?.success) {         
            const { data, currentPage, totalPages, currentTotalCount, totalCount } = response.data.data;
            const AdminUserRoleData: AdminUserRoleWithPaginationType = {
                adminUserRoleLogs: data,
                pagination: {
                    totalPages,
                    currentPage,                  
                    currentTotalCount,
                    totalCount,
                },
            };
            return AdminUserRoleData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async selectUserRole(){
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.SELECT_USER_ROLE}`,
                this.payload
            ).get()
            :
            await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.SELECT_USER_ROLE}`, this.payload, this.headers).post();
        let isUserRoleSelected;
        if(response?.data?.success==true) {
            isUserRoleSelected = response?.data;
            return isUserRoleSelected;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }


}

export default AdminUserListService;