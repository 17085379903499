import React, { useState } from "react";
import {
    Container,
    Grid,
    Box,
    Button
} from "@mui/material";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import PageTitle from "../common/PageTitle";
import { useTranslation } from "react-i18next";
import FileUpload from "./FileUpload";
import PayeeInfoTable from "./PayeeInfoTable";
import PaymentTable from "./PaymentTable";
import { useAppDispatch } from "../../redux/hooks";
import { commonActions } from "../../redux/features/common/commonSlice";

const EncryptionDecryptionTools = (props: any) => {
    const { showSnackbar, handleLoader,  } = props;
    const [selectAction, setSelectAction] =useState("");
    const [selectDataTable, setSelectDataTable] =useState("");
    const [uploadFileComp, setUploadFileComp] =useState<boolean>(false);
    const [payeeInfoTableComp, setPayeeInfoTableComp] =useState<boolean>(false);
    const [paymentTable, setPaymentTable] =useState<boolean>(false);

    const dispatch = useAppDispatch();
    const [t] = useTranslation();

    const actionHandleChange = (event: SelectChangeEvent) => {
        setSelectAction(event.target.value as string);
    };
    const selectHandleChange = (event: SelectChangeEvent) => {
        setSelectDataTable(event.target.value as string);
    };
 
    const handleComponentChange = () =>{
        if(selectAction == "Upload_a_file" ){
            setUploadFileComp(true);
            dispatch(commonActions.IS_UPLOAD(false));
        }else if(selectDataTable=="Payment_table"){
            setPaymentTable(true);
            dispatch(commonActions.IS_UPLOAD(true));
        }else{
            setPayeeInfoTableComp(true);
            dispatch(commonActions.IS_UPLOAD(true));
        }
    };
    
    const resetData = () =>{
        setSelectDataTable("");
        setSelectAction("");
    };
    
    return (
        <>
            {uploadFileComp ? <FileUpload handleLoader={handleLoader}  selectDataTable={selectDataTable}  setSelectAction={setSelectAction} showSnackbar={showSnackbar} setPaymentTable={setPaymentTable} setPayeeInfoTableComp={setPayeeInfoTableComp} setUploadFileComp={setUploadFileComp} setSelectDataTable={setSelectDataTable}/> : payeeInfoTableComp ? <PayeeInfoTable handleLoader={handleLoader}  showSnackbar={showSnackbar} setSelectAction={setSelectAction} setSelectDataTable={setSelectDataTable} setPaymentTable={setPaymentTable} setPayeeInfoTableComp={setPayeeInfoTableComp} setUploadFileComp={setUploadFileComp} /> : paymentTable ? <PaymentTable showSnackbar={showSnackbar} handleLoader ={handleLoader} setPaymentTable={setPaymentTable} setPayeeInfoTableComp={setPayeeInfoTableComp} setUploadFileComp={setUploadFileComp} setSelectAction={setSelectAction} setSelectDataTable={setSelectDataTable} /> :
                <div className="main">
                    <PageTitle title={t("encryption_decryption.encryption_decryption_tools")} />
                    <Container>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={4} md={4} lg={4} sx={{ py:2}}></Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4} sx={{ py:2}}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">{t("encryption_decryption.select_an_action")}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectAction}
                                        label={t("encryption_decryption.select_an_action")}
                                        onChange={actionHandleChange}>
                                        <MenuItem value="Upload_a_file">{t("encryption_decryption.upload_a_file")}</MenuItem>
                                        <MenuItem value="From_database">{t("encryption_decryption.from_database")}</MenuItem>
                                    </Select>
                                </FormControl>

                                <FormControl fullWidth sx={{my:5}}>
                                    <InputLabel id="demo-simple-select-label">{t("encryption_decryption.select_a_data_table")}</InputLabel>
                                  
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectDataTable}
                                        label={t("encryption_decryption.select_a_data_table")}
                                        onChange={selectHandleChange}>
                                        <MenuItem value="Payment_table"> {t("encryption_decryption.payment_table")}</MenuItem>
                                        <MenuItem value="order_table"> {t("encryption_decryption.order_table")}</MenuItem>
                           
                                    </Select>
                                </FormControl>

                                <Box className="encryption_decryption_box">
                                    <Button variant="contained"
                                        disabled={selectAction && selectDataTable ? false : true } 
                                        className="encryption_decryption_btn"
                                        onClick={handleComponentChange}>
                                        {t("encryption_decryption.next")}
                                    </Button>
                                         
                                    <Button variant="contained"
                                        disabled={selectAction && selectDataTable ? false : true}
                                        className="encryption_decryption_btn" onClick={resetData}>
                                        {t("encryption_decryption.reset")}
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4} sx={{ py:2}}></Grid>
                        </Grid>
            
                    </Container>
                </div>    
            }     
        </>
    );
};

export default EncryptionDecryptionTools;