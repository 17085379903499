import React, { useState, useEffect, useRef } from "react";
import { Container, Grid, Box, TextField,
    InputAdornment,
    IconButton,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    OutlinedInput,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import SearchIcon from "@mui/icons-material/Search";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useAppSelector } from "../../redux/hooks";
import { LIST_LIMIT, SHEET_NAME } from "../../constants/constants";
import DownloadButton from "../common/DownloadBtn";
import Skeleton from "../common/Skeleton";
import Pagination from "../common/Pagination";
import PageTitle from "../common/PageTitle";
import CommonButton from "../common/Button";
import CategoryListService from "./service/categoryService";
import downloadDataToExcel from "../../utils/ExcelDownloadUtility";
import lazyTypingUtils from "../../utils/lazyTypingUtils";
import Utility from "../../utils/Utility";

const CategoryList = (props:any) => {
    const utility = new Utility;
    const node = useRef<HTMLDivElement>(null);
    const firstPage = 1;
    const perPageLimit = useAppSelector((state) => state.common.perPageLimit);
    const [limit, setLimit] = useState(!perPageLimit ? LIST_LIMIT.ADMIN_LIST_LIMIT : perPageLimit );
    const [currentPage, setCurrentPage] = useState(firstPage);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [search, setSearch] = useState<string>("");
    const apiData: any[][] = [];
    const [skeleton, setSkeleton] = useState(false);
    const [appliedFilter1, setAppliedFilter1] = useState<any>("none");
    const [appliedFilter2, setAppliedFilter2] = useState<any>("none");
    const [loading, setLoading] = useState<boolean>(false);
    const [colFilter,setColFilter] =useState<string>("");
    const [direction,setDirection]=useState<string>("");
    const [colName,setColName]=useState<string>("");
    const [categoryData, setCategoryData] = useState <any>([]);
    const [isSearchChange,setIsSearchChange]=useState<boolean>(false);
    const [hideColumnArr,setHideColumnArr] = useState<any>([]);
    const [updateTime, setUpdateTime] = useState<any>();

    const { showSnackbar } = props;
    const { t } = useTranslation();

    const handleSorting =(changedColumn:any)=>{
        if(colName!=changedColumn){
            setDirection("asc");
        }else{
            setDirection(direction=="asc"? "desc":"asc");
        }
        setColName(changedColumn); 
        const columnName = changedColumn == "Category Code" || changedColumn=="カテゴリコード" ? "category_code": changedColumn == "Hierarchical Level" || changedColumn == "階層レベル" ? "level":changedColumn == "Page Title" || changedColumn == "掲載ページタイトル" ? "page_title" :""; 
        setColFilter(columnName); 
    };
    
    const columns = [
        {
            name: t("category_management.sno"),
            options: {
                filter: true,
                sort: false,
                display: hideColumnArr.includes("No.") ? false : true,
            },
        },
        {
            name: t("category_management.category_code"),
            options: {
                filter: true,
                sort: false,
                viewColumns: false,
                display: hideColumnArr.includes("カテゴリコード") ? false : true,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box component="span">
                            {data.label}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="category_code" ? <SwapVertIcon className="swapVertIcon"/> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>        
                        </Box>
                    );
                },  
            },
        },
        {
            name: t("category_management.category_hierarchy"),
            options: {
                filter: false,
                sort: false,
                display: hideColumnArr.includes("カテゴリ階層") ? false : true,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box component="span">
                            {data.label}
                        </Box>
                    );
                },  
            },
        },
        {
            name: t("category_management.page_title"),
            options: {
                filter: false,
                sort: false,
                display: hideColumnArr.includes("掲載ページタイトル") ? false : true,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box component="span">
                            {data.label}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="page_title" ? <SwapVertIcon className="swapVertIcon"/> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton> 
                        </Box>
                    );
                },  
            },
        },
        {
            name: t("category_management.hierarchical_level"),
            options: {
                filter: true,
                sort: false,
                display: hideColumnArr.includes("階層レベル") ? false : true,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box component="span">
                            {data.label}
                            <IconButton color="primary" size="small" onClick={()=>handleSorting(data.name)} >
                                {colFilter!="level" ? <SwapVertIcon className="swapVertIcon"/> : direction == "asc" ?<ArrowUpwardIcon className="sortingIconPosition"/>:<ArrowDownwardIcon className="sortingIconPosition"/> }
                            </IconButton>                   
                        </Box>
                    );
                }, 
            },
        },
        {
            name: t("category_management.posting_status"),
            options: {
                filter: true,
                sort: false,
                display: hideColumnArr.includes("掲載ステータス") ? false : true,
                customHeadLabelRender: (data:any) => {
                    return (
                        <Box component="span">
                            {data.label}
                        </Box>
                    );
                }, 
            },
        }
    ];

    useEffect(() => {
        if (appliedFilter1 || appliedFilter2 || colFilter || direction ) {
            categoryListApi(firstPage, limit);
        }
    }, [appliedFilter1, appliedFilter2, direction, colFilter]);

    const categoryListApi = (currentPage:number, limit:number) => {
        setSkeleton(true);
        setIsSearchChange(true);
        const payload = {};
        const isHitDummyURL = false;
        new CategoryListService(payload, isHitDummyURL)
            .categoryList(currentPage,limit, search, appliedFilter1, appliedFilter2 , direction, colFilter)
            .then((res:any) => {
                setLimit(limit);
                setCurrentPage(currentPage);
                setTotalCount(res?.pagination?.totalCount);
                setTotalPages(res?.pagination?.totalPages);
                setCategoryData(res?.categoryListLogs);
                setUpdateTime(res?.lastUpdatedAt);
                setSkeleton(false);
            })
            .catch((err:any) => {
                showSnackbar(err?.message, false);
            });
        apiTableData(); 
    };

    const downloadCategoryList = () => {
        showSnackbar(t("activity_log.download_started"), true);
        setLoading(true);
        const payload = {};
        const isHitDummyURL = false;
        new CategoryListService(payload, isHitDummyURL)
            .categoryListDownload(currentPage,limit, search, appliedFilter1, appliedFilter2,  direction, colFilter)
            .then((res:any) => {
                handleOnExport(res?.categoryListLogs);
                setSkeleton(false);
            })
            .catch((err:any) => {
                showSnackbar(err?.message, false);
                setSkeleton(false);
                setLoading(false);
            });
    };

    const updateCategory = () => {
        const payload = {};
        const isHitDummyURL = false;
        new CategoryListService(payload, isHitDummyURL)
            .updateCategory()
            .then((res:any) => {
                showSnackbar(res.message, true);
            })
            .catch((err:any) => {
                showSnackbar(err?.message, false);
            });
    };


    const handleOnExport = (downloadArray?: any) => {
        const downloadData: any = [];
        downloadArray?.map((item :any)=>{
            const rowData: any = {};
            if (!hideColumnArr.includes("カテゴリコード")) {
                rowData["カテゴリコード"] = item?.code;
            }
            if (!hideColumnArr.includes("カテゴリ階層")) {
                rowData["カテゴリ階層"] = item?.categoryHierarchy;
            }
            if (!hideColumnArr.includes("掲載ページタイトル")) {
                rowData["掲載ページタイトル"] = item?.pageTitle;
            }
            if (!hideColumnArr.includes("階層レベル")) {
                rowData["階層レベル"] = item?.level;
            }
            if (!hideColumnArr.includes("掲載ステータス")) {
                rowData["掲載ステータス"] = item?.publishStatus == "1" ? t("category_management.public") : t("category_management.private");
            }
            downloadData.push(rowData);
        });
        const fileName="カテゴリ一覧.xlsx";
        downloadDataToExcel(downloadData,fileName,SHEET_NAME.CATEGORY_LIST);
        setLoading(false);
    };
  
    const apiTableData = () => {
        {
            categoryData?.map((item: any, index: number) => {
                apiData[index] = [];
                apiData[index].push(
                    (currentPage - 1) * limit + index + 1,
                    item?.code,
                    item?.categoryHierarchy,
                    item?.pageTitle,
                    item?.level,
                    item?.publishStatus == "1" ? t("category_management.public")  : t("category_management.private") ,
                );
            });
        }
    };
    apiTableData();
  
    const handleSearchChange = (e: any) => {
        setIsSearchChange(true);
        const { value } = e.target;
        setSearch(value);
    };
    const onsearchApicall = () =>{
        if(isSearchChange){
            categoryListApi(firstPage, limit);
        } 
    };

    const propItem = [
        onsearchApicall,search
    ];
    lazyTypingUtils(propItem);

    const handelPublicationStatus = (e: any) => {
        const { value } = e.target;
        setAppliedFilter1(value);
    };

    const handelLevel = (e: any) => {
        const { value } = e.target;
        setAppliedFilter2(value);
    };

    const handleKeyPress = (e: any) => {
        if (e.keyCode == 13) {
            categoryData(currentPage,1);
        }
    };                                  
 
    return (
        <>
            <Container>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <PageTitle title={t("category_management.category_List")} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} sx={{py:6}} className="category-update">
                        {updateTime && <Box sx={{fontWeight:"500"}}>{t("category_management.last_updated_date")} {updateTime}</Box>}
                        <CommonButton route={window.location.pathname} functionToExecute={() => updateCategory()} className="category_btn" btnName={t("category_management.update_category")} disableCondition={false}/>
                    </Grid>
                </Grid>
            </Container>

            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            label={t("category_management.search_place_holder")}
                            onChange={handleSearchChange}
                            onKeyUp={handleKeyPress}
                            value={search}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            fullWidth
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={3.6}>
                           
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} lg={2}>
                        <FormControl size="small" fullWidth>
                            <InputLabel >
                                {t("category_management.posting_status")}
                            </InputLabel>
                            <Select
                                id="demo-simple-select"
                                size="small"
                                value={appliedFilter1}
                                onChange={(e) =>handelPublicationStatus(e)}
                                fullWidth
                                input={<OutlinedInput label={t("category_management.posting_status")}/>}>
                                <MenuItem value={"0"}>
                                    {t("category_management.private")}
                                </MenuItem>
                                <MenuItem value={"1"}>
                                    {t("category_management.public")}
                                </MenuItem>
                                <MenuItem value="none">{t("category_management.all")}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={2} lg={2}>
                        <FormControl size="small" fullWidth>
                            <InputLabel id="demo-multiple-name-label">{t("category_management.hierarchical_level")}</InputLabel>
                            <Select
                                id="demo-simple-select"
                                size="small"
                                value={appliedFilter2}
                                onChange={(e) =>handelLevel(e)}
                                fullWidth
                                input={<OutlinedInput label={t("category_management.hierarchical_level")} />}
                            >                                    
                                <MenuItem value="2">
                                    {t("category_management.level_2")}
                                </MenuItem>
                                <MenuItem value="3">
                                    {t("category_management.level_3")}
                                </MenuItem>
                                <MenuItem value="4">
                                    {t("category_management.level_4")}
                                </MenuItem> 
                                <MenuItem value="5">
                                    {t("category_management.level_5")}
                                </MenuItem>
                                <MenuItem value="none">{t("category_management.all")}</MenuItem>                                                        
                            </Select>
                        </FormControl>
                    </Grid>
                        
                    <Grid item xs={12} sm={12} md={2} lg={1.4} ref={node}>
                        <DownloadButton loading={loading} route={window.location.pathname} functionToExecute={downloadCategoryList} sx={{minWidth: { md: "90%" }}} className="margin_download_button float_right" btnName={t("pr.download")} disableCondition={ loading || categoryData?.length == 0}/>                       
                    </Grid>
                </Grid>
            </Container>

            <div className="main">
                {skeleton ? (
                    <Skeleton
                        varient="rectangular"
                        width={0}
                        height={60}
                        count="10"
                        my={0}
                    />
                ) : (
                    <Container className="first_table_cell_small_width fixed-table">
                        <MUIDataTable
                            title={""}
                            columns={columns}
                            data={apiData}
                            options={{
                                pagination: false,
                                print: false,
                                download: false,
                                filter: false,
                                search: false,
                                selectableRowsHideCheckboxes: true,
                                textLabels: {
                                    body: {
                                        noMatch: t("table_text.no_match").toString(),
                                    },
                                    viewColumns: {
                                        title: t("table_text.show_columns").toString(),
                                        titleAria: "Show/Hide Table Columns",
                                    },
                                    toolbar: {
                                        search: t("table_text.search").toString(),
                                        viewColumns: t("table_text.view_columns").toString(),
                                    },
                                },
                                searchPlaceholder: t("table_text.search_placeholder").toString(),
                                responsive: "standard",
                                onSearchChange: (searchText: any) => {
                                    setSearch(searchText ? searchText : "");
                                },
                                onViewColumnsChange: (changedColumn:string,action:string) => {
                                    const tempHideColArr=[...hideColumnArr];
                                    if(action==="remove"){
                                        changedColumn=="Category Code"?
                                            tempHideColArr.push("カテゴリコード"):
                                            changedColumn=="Category Hierarchy"?
                                                tempHideColArr.push("カテゴリ階層"):
                                                changedColumn=="Page Title"?
                                                    tempHideColArr.push("掲載ページタイトル"):
                                                    changedColumn=="Hierarchical Level"?
                                                        tempHideColArr.push("階層レベル"):
                                                        changedColumn=="Posting Status"?
                                                            tempHideColArr.push("掲載ステータス"):                                             
                                                            tempHideColArr.push(changedColumn);
                                        setHideColumnArr(tempHideColArr);
                                    }
                                    else{
                                        let index =-1;
                                        changedColumn=="Category Code"?
                                            index = tempHideColArr.indexOf("カテゴリコード"):
                                            changedColumn=="Category Hierarchy"?
                                                index = tempHideColArr.indexOf("カテゴリ階層"):
                                                changedColumn=="Page Title"?
                                                    index = tempHideColArr.indexOf("掲載ページタイトル"):
                                                    changedColumn=="Hierarchical Level"?
                                                        index = tempHideColArr.indexOf("階層レベル"):
                                                        changedColumn=="Posting Status"?
                                                            index = tempHideColArr.indexOf("掲載ステータス"):                                                           

                                                            index = tempHideColArr.indexOf(changedColumn);
                                        tempHideColArr.splice(index,1);
                                        setHideColumnArr(tempHideColArr);
                                    }
                                }  
                            }}
                        />
                    </Container>
                )}
            </div>
            <Pagination
                totalCount={totalCount}
                currentPage={currentPage}
                pageChange={categoryListApi}
                totalPage={totalPages}
                rowsPerPage={limit}
                rowsPerPageChange={setLimit}
            />
        </>
    );
};
export default CategoryList;



