import { API } from "../../../config";
import UnderMaintenanceType from "../../../interfaces/UnderMaintenanceInterface";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";

class UnderMaintenanceService {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async UnderMaintenanceGetService() {
        const response =
      this.isHitDummyURL && API.HIT_DUMMY_URL
          ? await new AxiosUtils(
              `${API.LOCAL_BASE_URL}${mockAPIPath.EDIT_MAINTENANCE}`,
              this.payload
          ).get()
          : await new AxiosUtils(
              `${API.BASE_URL}${serverAPIPath.EDIT_MAINTENANCE}`,
              this.payload,
              this.headers
          ).get();
        if(response?.data?.success == true)
        {   
            const UnderMaintenance: UnderMaintenanceType = response?.data?.data;
            return UnderMaintenance;
    
        } else if(response?.data?.success == false)
        {   
            throw new Error(response?.message);        
        } else{
            throw new Error(response);
        }
    }

    async UnderMaintenancePostService() {
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.POST_UNDER_MAINTENANCE}`, this.payload,  this.headers ).post();
        let SendDataUnderMaintenance;
        if (response?.data?.success == true) {
            SendDataUnderMaintenance = response?.data?.message;
            return SendDataUnderMaintenance;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }
}

export default UnderMaintenanceService;
