export const mockAPIPath = {
    STORELIST : "./data/storelist.json",
    INVITE_USER : "",
    VERIFY_INVITE_LINK : "./data/verifyInviteLink.json"
};

export const serverAPIPath = {
    STORELIST : "/user/sellers",
    INVITE_USER : "/invite/user",
    VERIFY_INVITE_LINK : "/invite/verify-invite-link/",
    REINVITE_USER: "/invite/user",
    DELETE_INVITED_USER: "/invite/user-list/",
};