import {createSlice, PayloadAction} from "@reduxjs/toolkit";

type InitialState = {
    permissionDetails : {
        accessToAllSeller?:number,
        userType?: number,
        roleId?:number,
        roleName?:string,
        emailPermissions?: [{
            name?:string,
            key?:string,
            value?:number
        }],
        modules?: {
            admin?: [{
                key?: string,
                moduleId?:number,
                name?: string,
                panel?:number,
                sellers?:Array<string>,
                permissions?: {
                    download?: number,
                    execute?: number,
                    view?: number,
                }
            }],
            seller?: [{
                key?: string,
                moduleId?:number,
                name?: string,
                panel?:number
                sellers?:Array<string>,
                permissions?: {
                    download?: number,
                    execute?: number,
                    view?: number,
                }
            }],
        }
    }
};

const initialState: InitialState = {
    permissionDetails : {
        accessToAllSeller:1,
        roleId:1,
        roleName:"",
        userType: 0,
        emailPermissions: [{
            name:"",
            key:"",
            value:0
        }],
        modules: {
            admin: [{
                key: "",
                moduleId:1,
                name: "",
                sellers:[],
                panel:0,
                permissions: {
                    download: 1,
                    execute: 1,
                    view: 1,
                }
            }],
            seller: [{
                key: "",
                moduleId:1,
                name: "",
                sellers:[],
                panel:1,
                permissions: {
                    download: 1,
                    execute: 1,
                    view: 1,
                }
            }],
        }
    }
};

const permissionSlice = createSlice({
    name: "permission",
    initialState,
    reducers: {
        SET_PERMISSIONS: (state, action: PayloadAction<object>) => {
            state.permissionDetails = action.payload;
        },
        RESET_PERMISSIONS: (state, action: PayloadAction<object>) => {
            state.permissionDetails = action.payload;
        }
    }
});

export const permissionInitialValue : InitialState = initialState;
export default permissionSlice.reducer;
export const permissionActions = permissionSlice.actions;