import { API } from "../../../config";
import { LIST_LIMIT } from "../../../constants/constants";
import { SellerNameLogWithPaginationType } from "../../../interfaces/SellerNameInterface";
import { PaymentHistoryLogWithPaginationType } from "../../../interfaces/paymentHistoryInterface";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";


class PaymentHistoryService {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;

    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async paymentHistoryList(
        page_id: number,
        limit = LIST_LIMIT.PAYMENT_HISTORY,
        to: Date | string,
        from: Date | string,
        search: string
    ) {
        const response = this.isHitDummyURL ? await new AxiosUtils(`${mockAPIPath.PAYMENT_HISTORY_LIST}`, this.payload, this.headers).get()
            : await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.PAYMENT_HISTORY_LIST}?page=${page_id}&limit=${limit}&to=${to}&from=${from}&seller=${search}`, this.payload, this.headers).get();

        if (response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount } = response.data.data;

            const SalesHistoryData: PaymentHistoryLogWithPaginationType = {
                paymentHistoryLogs: data,
                pagination: {
                    currentPage,
                    totalPages,
                    currentTotalCount,
                    totalCount,
                },
            };
            return SalesHistoryData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async downloadPaymentHistory(
        to: Date | string,
        from: Date | string,
        search: string
    ) {
        const response = this.isHitDummyURL ? await new AxiosUtils(`${mockAPIPath.PAYMENT_HISTORY_LIST}`, this.payload, this.headers).get()
            : await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.PAYMENT_HISTORY_LIST}?to=${to}&from=${from}&seller=${search}&download=true`, this.payload, this.headers).get();

        if (response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount } = response.data.data;

            const SalesHistoryData: PaymentHistoryLogWithPaginationType = {
                paymentHistoryLogs: data,
                pagination: {
                    currentPage,
                    totalPages,
                    currentTotalCount,
                    totalCount,
                },
            };
            return SalesHistoryData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async SearchSellerName(
        search: string
    ) {
        const response = this.isHitDummyURL
            ? await new AxiosUtils(
                `${mockAPIPath.PAYMENT_HISTORY_SELLER_NAME}`,
                this.payload
            ).get()
            : await new AxiosUtils(
                `${API.BASE_URL}${serverAPIPath.PAYMENT_HISTORY_SELLER_NAME}?search=${search}`,
                this.payload,
                this.headers
            ).get();

        if (response?.data?.success) {

            const { data } = response.data.data;


            const SellerNameData: SellerNameLogWithPaginationType = {
                sellerNameLogs: data,
            };
            return SellerNameData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async DummyUrlService() {
        const response = await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.DUMMY_URL}`, this.payload, this.headers).post();
        let responseMessage: any;
        if (response?.data?.success == true) {
            responseMessage = response?.data?.message;

            return responseMessage;
        }
        else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }

    async salesSatementService(sellerCode?: string, settlementCode?: string) {
        const response = this.isHitDummyURL ? await new AxiosUtils(`${mockAPIPath.SALES_STATEMENT}`, this.payload, this.headers).get()
            : await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.SALES_STATEMENT}?sellerCode=${sellerCode}&settlementPeriod=${settlementCode}`, this.payload, this.headers).get();
        if(response?.data?.success) {
            const { data } = response.data;
            return data;
        } else if(response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }
}
export default PaymentHistoryService;