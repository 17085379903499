
import { API } from "../../../config";
import { LIST_LIMIT } from "../../../constants/constants";
import { StuckedTransctionLogWithPaginationType } from "../../../interfaces/stuckedTransctionInterface";
import AxiosUtils from "../../../utils/AxiosUtils";
import Cookies from "../../../utils/Cookies";
import { mockAPIPath, serverAPIPath } from "../config/apiPath";

class StuckedTransctionService {
    private payload: object;
    private isHitDummyURL: boolean;
    private headers: object;
    constructor(payload = {}, isHitDummyURL = false) {
        this.payload = payload;
        this.isHitDummyURL = isHitDummyURL;
        this.headers = { "access-token": new Cookies("access-token").read() };
    }

    async stuckedTransctionList(
        page_id: number,
        limit = LIST_LIMIT.STUCKED_TRANSACTION_LIMIT,
    ) {
        const response = this.isHitDummyURL? await new AxiosUtils(`${mockAPIPath.STUCKED_TRANSACTION}`, this.payload).get() 
            : await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.STUCKED_TRANSACTION}?page=${page_id}&limit=${limit}`, this.payload, this.headers).get();
      
        if (response?.data?.success) {
            const { data, currentPage, totalPages, currentTotalCount, totalCount } =  response.data.data; 
            const stuckedTransactionData: StuckedTransctionLogWithPaginationType  = {
                stuckedTransctionLogs: data,
                pagination: {
                    currentPage,
                    totalPages,
                    currentTotalCount,
                    totalCount,
                },
            };
            return stuckedTransactionData;
        } else if (response?.success == false) {
            throw new Error(response?.message);
        } else {
            throw new Error(response);
        }
    }
    async stuckedTransction(
        id:number,
        storeId: number,
    ) {
        const response = this.isHitDummyURL? await new AxiosUtils(`${mockAPIPath.DELETE_STUCKED_TRANSACTION}`+ storeId, this.payload, this.headers).delete() 
            : await new AxiosUtils(`${API.BASE_URL}${serverAPIPath.DELETE_STUCKED_TRANSACTION}`, this.payload, this.headers).delete();
      
        let responeMessage: string;
        if(response?.data?.success==true) {
            responeMessage = response?.data?.message;
            return responeMessage;
        }
        else if(response?.success == false)
        {   
            throw new Error(response?.message);        
        }else{
            throw new Error(response);
        }
    }

}
export default StuckedTransctionService;