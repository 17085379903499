import React from "react";
import { Grid, Typography } from "@mui/material";

const Page404 = () => {

    return (
        <>
            <Grid container justifyContent={"center"} sx={{py: 30}}>
                <Typography fontSize={50}>404</Typography>
            </Grid>
        </>
    );
};

export default Page404;