import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import UserModel from "../../../models/user/userModel";

const user: UserModel = new UserModel();
const initialState = {
    user
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        LOGIN: (state, action: PayloadAction<UserModel>) => {
            state.user = action.payload;
        },
        REGISTER: (state, action: PayloadAction<UserModel>) => {
            state.user = action.payload;
        }
    }
});

export default userSlice.reducer;
export const userActions = userSlice.actions;