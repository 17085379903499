export const mockAPIPath = {
    ROLE_MANAGEMENT_LIST: "./data/roleManagementSystem.json",
    CREATE_USER_ROLE_LIST:"./data/createUserRole.json",
    SELLER_PERMISSION_LIST:"./data/sellerPermission.json",
    All_SELLER_PERMISSION_LIST:"./data/allSellerPermission.json",
    DELETE_USER_ROLE:"./data/rmsDelete.json",
    USER_ROLE_DETAILS:"./data/rmsDelete.json",
};

export const serverAPIPath = {
    ROLE_MANAGEMENT_LIST: "/rms/roles",
    CREATE_USER_ROLE_LIST:"/rms/modules",
    SELLER_PERMISSION_LIST:"/rms/roles/",
    All_SELLER_PERMISSION_LIST:"/store",
    DELETE_USER_ROLE:"/rms/roles/",
    USER_ROLE_DETAILS:"/rms/roles/"
};